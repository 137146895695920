import React, { useState } from 'react';

import './EnterPriceSolutions.css';

import animation1 from '../../assets/animations/animation1.json';
import animation2 from '../../assets/animations/animation2.json';
import animation3 from '../../assets/animations/animation3.json';
import animation4 from '../../assets/animations/animation4.json';

import ditem1 from '../../assets/images/doodle/item1.png';
import ditem2 from '../../assets/images/doodle/item2.png';
import ditem3 from '../../assets/images/doodle/item3.png';
import ditem4 from '../../assets/images/doodle/item4.png';

import yitem1 from '../../assets/images/yoodle/item1.png';
import yitem2 from '../../assets/images/yoodle/item2.png';
import yitem3 from '../../assets/images/yoodle/item3.png';
import yitem4 from '../../assets/images/yoodle/item4.png';

import nitem1 from '../../assets/images/noodle/item1.png';
import nitem2 from '../../assets/images/noodle/item2.png';
import nitem3 from '../../assets/images/noodle/item3.png';
import nitem4 from '../../assets/images/noodle/item4.png';

import titem1 from '../../assets/images/toodle/item1.png';
import titem2 from '../../assets/images/toodle/item2.png';
import titem3 from '../../assets/images/toodle/item3.png';
import titem4 from '../../assets/images/toodle/item4.png';

import AnimatedPage from "../AnimatedPage";
import SecationView from '../../components/SectionView/SecationView';
import EnterpriceCardComponent from '../../components/EnterpriceCardComponent/EnterpriceCardComponent';
import EnterpriceCardDetailsCard from '../../components/EnterpriceCardDetailsCard/EnterpriceCardDetailsCard';
import TopLeftQuaterCircle from "../../components/TopLeftQuaterCircle/TopLeftQuaterCircle";
import BackIcon from "../../components/BackIcon/BackIcon";
import DotImage from '../../assets/images/image12.png';
import BlueCircle from '../../assets/images/Bluecircle.png';

function EnterPriceSolutions() {

    const EnterPriceCardData = [
        {
            heading: 'YOODLE',
            subheading: 'Collaboration Suite',
            subtext: 'Communication is not collaboration. It is much more. ',
            animation: animation1,
            background: 'blue-lin-grad-BG',
            expandContent: `Collaboration is not just talking to each other or stand-alone applications 
                and scattered information. Yoodle is true collaboration. How? Yoodle is available on any 
                piece of data within any application.`,
            subItems: [
                {
                    heading: 'SOCIAL CHAT',
                    subHeading: 'Empowering You to Work Together.',
                    content: `Social Chat is world’s first truly contextual and
                        collaborative chat. With Social Chat, you can
                        chat about the data and around the data.`,
                    path: '/socialchat',
                    icon: yitem1
                },
                {
                    heading: 'SOCIAL MESSAGING',
                    subHeading: 'Context Within Message.',
                    content: `Social Messaging will connect most important entities of the 
                        enterprise applications like data, documents, transactions and people 
                        together within one place to continue business activity – within the message.`,
                    path: '/',
                    icon: yitem2
                },
                {
                    heading: 'Social wall',
                    subHeading: 'Lorem Ipsum amit dolor',
                    content: `Nisl, et ornare dui lacinia. Sit sollicitudin viverra ultrices sed 
                        auctor in velit, nam nulla. Non faucibus.Nisl, et ornare dui lacinia. `,
                    path: '/',
                    icon: yitem3
                },
                {
                    heading: 'SOCIAL COMMENTS',
                    subHeading: 'Sit sollicitudin viverra ultrices sed.',
                    content: `Nisl, et ornare dui lacinia. Sit sollicitudin viverra ultrices sed auctor 
                        in velit, nam nulla. Non faucibus.Nisl, et ornare dui lacinia.  `,
                    path: '/',
                    icon: yitem4
                }
            ]
        },
        {
            heading: 'TOODLE',
            subheading: 'Business Application Suite',
            subtext: 'More than just assistance',
            animation: animation4,
            background: 'orange-lin-grad-BG',
            expandContent: `Empowering knowledge workers with the big picture through a self-managed ecosystem 
                that contextualises and personalises data and aligns it to the user's requirement at every step.`,
            subItems: [
                {
                    heading: 'SOCIAL FORMS',
                    subHeading: 'Forms that not only take information, but give information too',
                    content: `Social Forms envisions an experience where the user's data 
                        is protected, they don’t have to worry about losing their work, `,
                    path: '/socialform',
                    icon: titem1
                },
                {
                    heading: 'YEDY',
                    subHeading: 'Lorem Ipsum amit dolor',
                    content: `Nisl, et ornare dui lacinia. Sit sollicitudin viverra ultrices 
                        sed auctor in velit, nam nulla. Non faucibus.Nisl, et ornare dui lacinia.`,
                    path: '/',
                    icon: titem2
                },
                {
                    heading: 'KYP',
                    subHeading: 'Lorem Ipsum amit dolor',
                    content: `Nisl, et ornare dui lacinia. Sit sollicitudin viverra ultrices 
                        sed auctor in velit, nam nulla. Non faucibus.Nisl, et ornare dui lacinia.`,
                    path: '/',
                    icon: titem3
                },
                {
                    heading: 'TAP',
                    subHeading: 'Sharing data and documents made easy',
                    content: `What if your phone became an extension of your laptop or PC? 
                        Combining the comfort and convenience of a mobile phone with the functionality of a computer`,
                    path: '/tap',
                    icon: titem4
                },
            ]
        },
        {
            heading: 'DOODLE',
            subheading: 'Productivity Suite',
            subtext: 'More than efficiency',
            animation: animation3,
            background: 'voilate-lin-grad-BG ',
            expandContent: `Digital Harbor arms enterprise workers with technology that is intuitive, 
                predictive and self-manageable.`,
            subItems: [
                {
                    heading: 'SOCIAL TEXT',
                    subHeading: 'Communication that leads to Action',
                    content: `Social Text revolutionizes the way businesses communicate. From 
                        visually exciting graphics to hypermedia that includes cards and tags, `,
                    path: '/socialtext',
                    icon: ditem1
                },
                {
                    heading: 'DOBAR',
                    subHeading: 'Tools that assist every task',
                    content: `What if you never had to switch screens or learn complex keyboard 
                        shortcuts to perform basic functions like taking a screenshot? `,
                    path: '/dobar',
                    icon: ditem2
                },
                {
                    heading: 'SOCIAL SHARE',
                    subHeading: 'Lorem Ipsum amit dolor',
                    content: `Nisl, et ornare dui lacinia. Sit sollicitudin viverra ultrices 
                        sed auctor in velit, nam nulla. Non faucibus.Nisl, et ornare dui lacinia.`,
                    path: '/',
                    icon: ditem3
                },
                {
                    heading: 'SOCIAL DOCS',
                    subHeading: 'Lorem Ipsum amit dolor',
                    content: `Nisl, et ornare dui lacinia. Sit sollicitudin viverra ultrices 
                        sed auctor in velit, nam nulla. Non faucibus.Nisl, et ornare dui lacinia.`,
                    path: '/',
                    icon: ditem4
                }
            ]

        },
        {
            heading: 'NOODLE',
            subheading: 'Business intelligence Suite',
            subtext: 'The future is a guided business experience.',
            animation: animation2,
            background: 'blue2-lin-grad-BG',
            expandContent: `Empowering enterprise interactions through directed prompts and intelligent, 
                filtered and prioritised interactions between knowledge workers and businesses.`,
            subItems: [
                {
                    heading: 'SOCIAL PROFILE',
                    subHeading: 'Lorem Ipsum amit dolor',
                    content: `Nisl, et ornare dui lacinia. Sit sollicitudin viverra ultrices 
                        sed auctor in velit, nam nulla. Non faucibus.Nisl, et ornare dui lacinia.`,
                    path: '/',
                    icon: nitem1
                },
                {
                    heading: 'SOCIAL SEARCH',
                    subHeading: 'Transform search into discovery',
                    content: `Searching for data will never be complicated and time-consuming anymore. `,
                    path: '/socialsearch',
                    icon: nitem2
                },
                {
                    heading: 'SOCIAL TAGS',
                    subHeading: 'Lorem Ipsum amit dolor',
                    content: `Nisl, et ornare dui lacinia. Sit sollicitudin viverra ultrices 
                        sed auctor in velit, nam nulla. Non faucibus.Nisl, et ornare dui lacinia.`,
                    path: '/',
                    icon: nitem3
                },
                {
                    heading: 'SOCIAL ANALYTICS',
                    subHeading: 'Lorem Ipsum amit dolor',
                    content: `Nisl, et ornare dui lacinia. Sit sollicitudin viverra ultrices 
                        sed auctor in velit, nam nulla. Non faucibus.Nisl, et ornare dui lacinia.`,
                    path: '/',
                    icon: nitem4
                }
            ]
        }
    ]

    const [activeElement, setActiveElement] = useState();

    const setDataInActiveElement = (e) => {
        window.scrollTo(0, 0);
        setActiveElement(e);
        console.log(e);
    }

    return (
        <AnimatedPage>
            <div className='enterprice-solutions-container'>
            <TopLeftQuaterCircle />
                <SecationView>
                <a href='/social'><BackIcon /></a>
                    <h1 className='page-heading theme-heading'>
                        EXPLORE OUR <br /> ENTERPRISE SOLUTIONS
                    </h1>

                    {
                        activeElement ?
                            <div onClick={() => setActiveElement()} className={'card-details-container ' + activeElement.background}>
                                <h1 className='card-details-heading'>{activeElement.heading}</h1>
                                <h3 className='card-details-subheading'>{activeElement.subheading}</h3>
                                <p className='card-detials-card-discription'>{activeElement.expandContent}</p>
                                <div className='card-details-grid-view'>
                                    {
                                        activeElement.subItems.map((e, i) => {
                                            return (
                                                <EnterpriceCardDetailsCard key={i} data={e} />
                                            )
                                        })
                                    }
                                </div>
                            </div> : ''
                    }

                    <div className='enterprice-cards-container'>
                    <img className="dot-halfimage product-dotimage" src={DotImage} />
                        {
                            EnterPriceCardData.map((e, i) => {
                                return (
                                    (!activeElement || activeElement.heading !== e.heading) && <EnterpriceCardComponent key={i} onClick={() => setDataInActiveElement(e)} data={e} />

                                )
                            })
                        }
                        {/* <img className="bluecircle" src={BlueCircle} /> */}
                    </div>
                    
                </SecationView>
            </div>
        </AnimatedPage>
    )
}

export default EnterPriceSolutions
import React from "react";

import arrow from "../../assets/images/Rectangle 156.png";

const NodeGraphCard = ({ title, desc, link }) => {
  return (
    <div className="nodegraph-card-container">
      <h2 className="nodegraph-card-title"> {title}</h2>
      <p className="nodegraph-card-description">{desc}</p>

      <p className="nodegraph-card-link">
        {link}
        <span className="nodegraph-card-link-icon">
          {" "}
          <img src={arrow} />
        </span>
      </p>
    </div>
  );
};

export default NodeGraphCard;

import React, { useState } from 'react';

import './PieChartComponent.css';

import childOneImage from '../../assets/images/CircleCountImages/childCircle1.png';
import childTwoImage from '../../assets/images/CircleCountImages/childCircle2.png';
import childThreeImage from '../../assets/images/CircleCountImages/childCircle3.png';
import childFourImage from '../../assets/images/CircleCountImages/childCircle4.png';
import childFiveImage from '../../assets/images/CircleCountImages/childCircle4.png';
import circleHeaderIcon from '../../assets/images/CircleCountImages/circleHeaderIcon.png';

function PieChartComponent() {

    const [activeElement, setActiveElement] = useState();

    let counterData = [
        {
            head: "BUILD AROUND THE USER",
            content: "Understands the intent of the user and acts like a personal guide."
        },
        {
            head: "SEARCH BEYOND DATA",
            content: "Search any data as well as transaction in natural language."
        },
        {
            head: "POLYMORPHIC DISPLAY",
            content: "Displays serach results based on user behavior & device."
        },
        {
            head: "DYNAMIC SEARCH RESULT",
            content: "Search result are no longer tabular, it is rich in information & are actionable."
        }
    ]

    const handleActiveState = (val) => {
        val != activeElement ? setActiveElement(val) : setActiveElement();
    }

    return (
        <div className='pie-number-component-contianer pie-chart'>
  
            <div className='pie-number-contianer'>
                <div className='outer-pie-for-border'></div>
                <div className='parent-pie-contianer'>
                    <div className='parent-pie-text-contianer mv-sf-head'>
                        WHAT MAKES SOCIAL CHAT <br />
                        <span className='parent-wow-contianer'>WOW<span className='parent-ques-contianer'>?</span></span>
                    </div>  
                    <div className='parent-pie-text-contianer mv-sf'>
                        WHAT MAKES<br /> SOCIAL CHAT <br /> 
                        <span className='parent-wow-contianer-pie'>WOW<span className='parent-ques-contianer'>?</span></span>
                    </div>                 
                </div>
                
                <div onClick={() => handleActiveState(6)} className='child-pie inner-pie-6'>
                    <div className={'child-pie-number-contianer ' + (activeElement == 6 ? 'animate-number' : '')}>6</div>
                    <span className={'clild-image-contianer ' + (activeElement == 6 ? 'animate-image' : '')}>
                        <img className='child-pie-image' src={childFiveImage} alt='child1' />
                    </span>
                </div>

                <div onClick={() => handleActiveState(5)} className='child-pie inner-pie-5'>
                    <div className={'child-pie-number-contianer ' + (activeElement == 5 ? 'animate-number' : '')}>5</div>
                    <span className={'clild-image-contianer ' + (activeElement == 5 ? 'animate-image' : '')}>
                        <img className='child-pie-image' src={childFiveImage} alt='child1' />
                    </span>
                </div>

                <div onClick={() => handleActiveState(1)} className='child-pie inner-pie-1'>
                    <div className={'child-pie-number-contianer ' + (activeElement == 1 ? 'animate-number' : '')}>1</div>
                    <span className={'clild-image-contianer ' + (activeElement == 1 ? 'animate-image' : '')}>
                        <img className='child-pie-image' src={childOneImage} alt='child1' />
                    </span>
                </div>

                <div onClick={() => handleActiveState(2)} className='child-pie inner-pie-2'>
                    <div className={'child-pie-number-contianer ' + (activeElement == 2 ? 'animate-number' : '')}>2</div>
                    <span className={'clild-image-contianer ' + (activeElement == 2 ? 'animate-image' : '')}>
                        <img className='child-pie-image' src={childTwoImage} alt='child1' />
                    </span>
                </div>

                <div onClick={() => handleActiveState(4)} className='child-pie inner-pie-4'>
                    <div className={'child-pie-number-contianer ' + (activeElement == 4 ? 'animate-number' : '')}>4</div>
                    <span className={'clild-image-contianer ' + (activeElement == 4 ? 'animate-image' : '')}>
                        <img className='child-pie-image' src={childFourImage} alt='child1' />
                    </span>
                </div>

                <div onClick={() => handleActiveState(3)} className='child-pie inner-pie-3'>
                    <div className={'child-pie-number-contianer ' + (activeElement == 3 ? 'animate-number' : '')}>3</div>
                    <span className={'clild-image-contianer ' + (activeElement == 3 ? 'animate-image' : '')}>
                        <img className='child-pie-image' src={childThreeImage} alt='child1' />
                    </span>
                </div>
            </div>

            <div className='pie-number-info-contianer'>
                {/* {
                    activeElement ? <div className='pie-number-title-contianer'>
                        <img className='emoji-icon' alt='emoji-icon' src={circleHeaderIcon} />
                        <p className='pie-number-title'>{counterData[activeElement - 1].title}</p>
                    </div> : ''
                } */}

                <div className={'discription-contianer-number-counter ' + (activeElement ? 'animate-dis-container-pie' : '')}>
                    <div className='pie-number-heading-contianer'>
                        {activeElement ? counterData[activeElement - 1].head : ''}
                    </div>
                    <div className='pie-number-discription-contianer'>
                        <p className='pie-number-discription'>
                            {activeElement ? counterData[activeElement - 1].content : ''}
                        </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default PieChartComponent
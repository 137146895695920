import React from 'react';

import ReactPlayer from 'react-player';

import './VideoPlayerComponent.css';
import videoThambnail from '../../assets/images/videoThambnail.png';
import playIcon from '../../assets/images/playIcon.png'

const VideoPlayerComponent = (props) => {

    return (

        <div className='video-player-contianer-com-contianer'>

            <div className='video-heading-continaer'>
                <div className='video-heading-border-contianer'>
                    <div className='v-h-l-border'></div>
                </div>
                <h3 className='pageSubHeading video-heading sf-head'>EXPERIENCE {props.heading}</h3>
                <div className='video-heading-border-contianer'>
                    <div className='v-h-l-border'></div>
                </div>
            </div>

            <div>
                {
                    props.children
                }
            </div>
            <div className='video-bg-line'></div>
            <div className='video-player-contianer '>
               
                <ReactPlayer
                    style={{ margin: 'auto' }}
                    height={'400px'}
                    width={'712px'}
                    url='https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4'
                    controls={true}
                    playIcon={<img className='play-icon' src={playIcon} />}
                    light={videoThambnail}
                    className='videoplayer'
                />
            </div>
        </div>
    )
}

export default VideoPlayerComponent
import React from "react";
import { BrowserRouter } from "react-router-dom";

import AnimatedRouter from "./AnimatedRouter";

const ScreenRouter = (props) => {

    return (
        <BrowserRouter>

            <AnimatedRouter />

        </BrowserRouter>
    )
}

export default ScreenRouter  
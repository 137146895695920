import React, { useEffect, useRef } from 'react';
import './KeyboardCompoent.css'

const KeyboardCompoent = (props) => {

    const keyBoardRefs = useRef([]);

    useEffect(() => {
        window.addEventListener('keydown', onKeyHit)
    }, [])

    const onKeyHit = (e) => {
        if (e.key.length == 1) {
            props.setKeyboardKey(e.key)
        }
        keyBoardRefs.current[e.code].classList.toggle('pressed-key');
        setTimeout(function () {
            keyBoardRefs.current[e.code].classList.toggle('pressed-key');
        }, 500)

    }

    return (
        <div className='keyboard-component-comtainer'>
            <div className="keyboard">
                <div className="keyboard__row keyboard__row--h1">
                    <div data-key="27" className="key--word">
                        <span>esc</span>
                    </div>
                    <div data-key="112" className="key--fn">
                        <span>F1</span>
                    </div>
                    <div data-key="113" className="key--fn">
                        <span>F2</span>
                    </div>
                    <div data-key="114" className="key--fn">
                        <span>F3</span>
                    </div>
                    <div data-key="115" className="key--fn">
                        <span>F4</span>
                    </div>
                    <div data-key="116" className="key--fn">
                        <span>F5</span>
                    </div>
                    <div data-key="117" className="key--fn">
                        <span>F6</span>
                    </div>
                    <div data-key="118" className="key--fn">
                        <span>F7</span>
                    </div>
                    <div data-key="119" className="key--fn">
                        <span>F8</span>
                    </div>
                    <div data-key="120" className="key--fn">
                        <span>F9</span>
                    </div>
                    <div data-key="121" className="key--fn">
                        <span>F10</span>
                    </div>
                    <div data-key="122" className="key--fn">
                        <span>F11</span>
                    </div>
                    <div data-key="123" className="key--fn">
                        <span>F12</span>
                    </div>
                    <div data-key="n/a" className="key--word">
                        <span>pwr</span>
                    </div>
                </div>

                <div className="keyboard__row">
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Backquote'] = el)}>
                        <div>~</div>
                        <div>`</div>
                    </div>
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Digit1'] = el)}>
                        <div>!</div>
                        <div>1</div>
                    </div>
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Digit2'] = el)}>
                        <div>@</div>
                        <div>2</div>
                    </div>
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Digit3'] = el)}>
                        <div>#</div>
                        <div>3</div>
                    </div>
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Digit4'] = el)}>
                        <div>$</div>
                        <div>4</div>
                    </div>
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Digit5'] = el)}>
                        <div>%</div>
                        <div>5</div>
                    </div>
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Digit6'] = el)}>
                        <div>^</div>
                        <div>6</div>
                    </div>
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Digit7'] = el)}>
                        <div>&</div>
                        <div>7</div>
                    </div>
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Digit8'] = el)}>
                        <div>*</div>
                        <div>8</div>
                    </div>
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Digit9'] = el)}>
                        <div>(</div>
                        <div>9</div>
                    </div>
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Digit0'] = el)}>
                        <div>)</div>
                        <div>0</div>
                    </div>
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Minus'] = el)}>
                        <div>_</div>
                        <div>-</div>
                    </div>
                    <div className="key--double" ref={(el) => (keyBoardRefs.current['Equal'] = el)}>
                        <div>+</div>
                        <div>=</div>
                    </div>
                    <div className="key--bottom-right key--word key--w4" ref={(el) => (keyBoardRefs.current['Backspace'] = el)}>
                        <span>delete</span>
                    </div>
                </div>

                <div className="keyboard__row">
                    <div ref={(el) => (keyBoardRefs.current['Tab'] = el)} className="key--bottom-left key--word key--w4" data-key="9">
                        <span>tab</span>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['KeyQ'] = el)} className="key--letter">Q</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyW'] = el)} className="key--letter">W</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyE'] = el)} className="key--letter">E</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyR'] = el)} className="key--letter">R</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyT'] = el)} className="key--letter">T</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyY'] = el)} className="key--letter">Y</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyU'] = el)} className="key--letter">U</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyI'] = el)} className="key--letter">I</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyO'] = el)} className="key--letter">O</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyP'] = el)} className="key--letter">P</div>
                    <div ref={(el) => (keyBoardRefs.current['BracketLeft'] = el)} className="key--double" data-char="{[">
                        <div>{"{"}</div>
                        <div>{"["}</div>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['BracketRight'] = el)} className="key--double" data-key="221" data-char="}]">
                        <div>{"}"}</div>
                        <div>{"]"}</div>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['Backslash'] = el)} className="key--double" data-key="220" data-char="|\">
                        <div>|</div>
                        <div>\</div>
                    </div>
                </div>
                <div className="keyboard__row">
                    <div ref={(el) => (keyBoardRefs.current['CapsLock'] = el)} className="key--bottom-left key--word key--w5" data-key="20">
                        <span>caps lock</span>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['KeyA'] = el)} className="key--letter" data-char="A">A</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyS'] = el)} className="key--letter" data-char="S">S</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyD'] = el)} className="key--letter" data-char="D">D</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyF'] = el)} className="key--letter" data-char="F">F</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyG'] = el)} className="key--letter" data-char="G">G</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyH'] = el)} className="key--letter" data-char="H">H</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyJ'] = el)} className="key--letter" data-char="J">J</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyK'] = el)} className="key--letter" data-char="K">K</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyL'] = el)} className="key--letter" data-char="L">L</div>
                    <div ref={(el) => (keyBoardRefs.current['Semicolon'] = el)} className="key--double" data-key="186">
                        <div>:</div>
                        <div>;</div>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['Quote'] = el)} className="key--double" data-key="222">
                        <div>"</div>
                        <div>'</div>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['Enter'] = el)} className="key--bottom-right key--word key--w5" data-key="13">
                        <span>return</span>
                    </div>
                </div>
                <div className="keyboard__row">
                    <div ref={(el) => (keyBoardRefs.current['ShiftLeft'] = el)} className="key--bottom-left key--word key--w6" data-key="16">
                        <span>shift</span>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['KeyZ'] = el)} className="key--letter" data-char="Z">Z</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyX'] = el)} className="key--letter" data-char="X">X</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyC'] = el)} className="key--letter" data-char="C">C</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyV'] = el)} className="key--letter" data-char="V">V</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyB'] = el)} className="key--letter" data-char="B">B</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyN'] = el)} className="key--letter" data-char="N">N</div>
                    <div ref={(el) => (keyBoardRefs.current['KeyM'] = el)} className="key--letter" data-char="M">M</div>
                    <div ref={(el) => (keyBoardRefs.current['Comma'] = el)} className="key--double" data-key="188">
                        <div>&lt;</div>
                        <div>,</div>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['Period'] = el)} className="key--double" data-key="190">
                        <div>&gt;</div>
                        <div>.</div>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['Slash'] = el)} className="key--double" data-key="191">
                        <div>?</div>
                        <div>/</div>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['ShiftRight'] = el)} className="key--bottom-right key--word key--w6" data-key="16-R">
                        <span>shift</span>
                    </div>
                </div>
                <div className="keyboard__row keyboard__row--h3">
                    <div className="key--bottom-left key--word">
                        <span>fn</span>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['ControlLeft'] = el)} className="key--bottom-left key--word key--w1" data-key="17">
                        <span>control</span>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['AltLeft'] = el)} className="key--bottom-left key--word key--w1" data-key="18">
                        <span>option</span>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['MetaLeft'] = el)} className="key--bottom-right key--word key--w3" data-key="91">
                        <span>command</span>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['Space'] = el)} className="key--double key--right key--space" data-key="32" data-char=" ">
                        &nbsp;
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['MetaRight'] = el)} className="key--bottom-left key--word key--w3" data-key="93-R">
                        <span>command</span>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['AltRight'] = el)} className="key--bottom-left key--word key--w1" data-key="18-R">
                        <span>option</span>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['ArrowLeft'] = el)} data-key="37" className="key--arrow">
                        <span>&#9664;</span>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['ArrowUp'] = el, keyBoardRefs.current['ArrowDown'] = el)} className="key--double key--arrow--tall" data-key="38">
                        <div>&#9650;</div>
                        <div>&#9660;</div>
                    </div>
                    <div ref={(el) => (keyBoardRefs.current['ArrowRight'] = el)} data-key="39" className="key--arrow">
                        <span>&#9654;</span>
                    </div>
                </div>
                <p className='keyboard-under-title'>START PRESSING KEY ON YOUR KEYBOARD</p>
            </div>
        </div >
    )
}

export default KeyboardCompoent
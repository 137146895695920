import React from 'react';
import './Careers.css';

import Arrow from '../../assets/images/DownArrow.png';
import carrerBannerImage from '../../assets/images/carrerBannerImage.png';
import key1 from '../../assets/images/key1.png';
import key2 from '../../assets/images/key2.png';
import key3 from '../../assets/images/key3.png';

import AnimatedPage from "../AnimatedPage";
import SecationView from "../../components/SectionView/SecationView";
import DotImage from '../../assets/images/image12.png';
import CareersCardComponent from '../../components/CareersCardComponent/CareersCardComponent';
import CareersCardComponentSec from '../../components/CareersCardComponentSec/CareersCardComponentSec';
import CareersTeamCard from '../../components/CareersTeamCard/CareersTeamCard';
import TopLeftQuaterCircle from "../../components/TopLeftQuaterCircle/TopLeftQuaterCircle";
import DotConnectionLeft from '../../components/DotConnectionLeft/DotConnectionLeft';
import DotConnectionBottomRight from '../../components/DotConnectionBottomRight/DotConnectionBottomRight';
import BackIcon from "../../components/BackIcon/BackIcon";
import ProductText from '../../components/ProductText/ProductText';
import TopQuaterCircleSACC from '../../components/TopQuaterCircleSACC/TopQuaterCircleSACC';
import GetInTouchSection from '../../components/GetInTouchSection/GetInTouchSection';
import FooterComponent from '../../components/FooterComponent/FooterComponent';

const Careers = () => {

    const keyPoints = [
        {
            heading: 'Innovative Work Culture',
            content: 'we nurture unorthodox thinking and help you to go the extra mile',
            image: key1
        },
        {
            heading: 'A Cool Place to Work',
            content: 'we have created a space the promotes fun as much as it does work',
            image: key2
        },
        {
            heading: 'A Place to Learn and Grow',
            content: 'we always aim to nuture your learning and growth under our umbrella',
            image: key3
        }
    ];

    return (
        <AnimatedPage>

            <div className='c-p-contianer'>
                <div className='c-p-banner-contianer'>
                <TopLeftQuaterCircle />
                <TopQuaterCircleSACC />
                <DotConnectionLeft />
                    <SecationView>
                        <a href='/aboutus'><BackIcon /></a>
                        <div className="c-p-banner-sections">
                            <h1 className="pageHeading c-p-banner-heading">
                                CHALLENGE THE<br /> STATUS-QUO &<br /> PUSH THE<br /> BOUNDARIES OF<br /> INNOVATION<br /> WITH US
                            </h1>
                            <div className='c-p-banner-image-container'>
                                <img className='c-p-banner-image' src={carrerBannerImage} alt='carrer image' />
                            </div>
                        </div>
                        <ProductText />
                        <img className="down-arrow-image local-down-arrow-image-class career-arrow" src={Arrow} />
                    </SecationView>
                </div>

                <div className='c-p-section-2-contianer'>
                    <h2 className='pageSubHeading career-head'>
                        25 YEARS BACK, A COUPLE OF I.T.<br />
                        ENTHUSIASTS DECIDED TO FIND A BETTER <br />
                        WAY TO SCREEN CUSTOMERS FOR THE<br />
                        BANKING INDUSTRY.
                    </h2>
                </div>
                <img className="dot-halfimage" src={DotImage} />
                <div className='c-p-section-3-contianer'>
                    <h2 className='product-page-name-heading c-p-sec-3-heading'>WHY WORK WITH US?</h2>
                    <div className='c-p-key-points-contianer'>
                        {
                            keyPoints.map((e, i) => {
                                return (
                                    <div key={i} className='c-p-key-point-contianer'>
                                        {/* <div className='c-p-point-circle-contianer'></div> */}
                                        <img src={e.image} className="c-p-point-image" />
                                        <p className='c-p-point-heading'><b>{e.heading}</b></p>
                                        <p className='c-p-point-dis'>{e.content}</p>
                                    </div>
                                )
                            })
                        }
                    </div>                  
                    <div className='careers-card-contianer'>
                    <h2 className='product-page-name-heading c-p-sec-3-heading career-head'>WE’VE GOT A SEAT FOR YOU</h2>
                        <CareersCardComponent />
                        <CareersCardComponentSec /> 
                        {/* <DotConnectionBottomRight /> */}
                    </div>           
                </div>
                <div className='c-p-section-4-contianer'>
                    <button className="career-button">VIEW ALL</button>
                    <h2 className='product-page-name-heading c-p-sec-3-heading career-head'>FIND THE RIGHT TEAM</h2>
                    <CareersTeamCard />
                </div>
                <GetInTouchSection heading={"DON't SEE YOUR PERFECT ROLE? GET IN TOUCH WITH US."}/>
                <FooterComponent />
            </div>

        </AnimatedPage>
    )
}

export default Careers
import React from 'react';

import './BackIconTD.css';
import Back from '../../assets/images/Group31.png';

const BackIconTD = (props) => {
    return (
            <img style={props.style} className='backicon-td' alt='upper' src={Back} />
    )
}

export default BackIconTD
import React from 'react';

import './TopLeftQuaterCircle.css';
import '../../screens/SocialWorkspace/SocialWorkspace.css';
import topBannerQuaterCircle from '../../assets/images/topBannerQuaterCircle.png';

const TopLeftQuaterCircle = (props) => {
    return (
        <div className='banner-top-left-half-cicle-imgae-contianer sw-circle"'>
            <img style={props.style} className='banner-top-left-half-cicle-imgae' alt='upper' src={topBannerQuaterCircle} />
        </div>
    )
}

export default TopLeftQuaterCircle
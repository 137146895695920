import React from 'react';

import './TopQuaterCircleSACC.css';
import topBannerQuaterCircle from '../../assets/images/topBannerQuaterCircle.png';

const TopQuaterCircleSACC = (props) => {
    return (
        <div className='banner-top-contianer-sacc'>
            <img style={props.style} className='banner-top-imgae-sacc' alt='upper' src={topBannerQuaterCircle} />
        </div>
    )
}

export default TopQuaterCircleSACC
import React from 'react';

import './BackIcon.css';
import Back from '../../assets/images/Group31.png';

const BackIcon = (props) => {
    return (
            <img style={props.style} className='banner-back-half-cicle-imgae' alt='upper' src={Back} />
    )
}

export default BackIcon
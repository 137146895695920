import React from 'react';

import './BottomQuaterCircle.css';
import bottomBannerQuaterCircle from '../../assets/images/bottomBannerQuaterCircle.png';

const BottomQuaterCircle = (props) => {
    return (
        <div className='banner-bottom-half-cicle-imgae-contianer'>
            <img style={props.style} className='banner-bottom-image' alt='bottom' src={bottomBannerQuaterCircle} />
        </div>
    )
}

export default BottomQuaterCircle
import React from 'react';

import './DoBar.css';
import DoBarBannerImage from '../../assets/images/DoBarBannerImage.png';
import dbBannerIcon from '../../assets/images/dbBannerIcon.png';
import DoBarSec2Image from '../../assets/images/DoBarSec2Image.png';
import item1 from '../../assets/images/DobarIcons/item1.png';
import item2 from '../../assets/images/DobarIcons/item2.png';
import item3 from '../../assets/images/DobarIcons/item3.png';
import item4 from '../../assets/images/DobarIcons/item4.png';

import AnimatedPage from '../AnimatedPage';
import ThemeButton from '../../components/ThemeButton/ThemeButton';
import CarouselSlidesContainer from '../../components/CarouselSlidesContainer/CarouselSlidesContainer';
import WhatMakesComponent from '../../components/WhatMakesComponent/WhatMakesComponent';
import VideoPlayerComponent from '../../components/VideoPlayerComponent/VideoPlayerComponent';
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import ProductText from '../../components/ProductText/ProductText';
import BackIconTD from "../../components/BackIconTD/BackIconTD";
import TopQuaterCircle from "../../components/TopQuaterCircle/TopQuaterCircle";
import Arrow from '../../assets/images/Arrow1.png';

const DoBar = () => {

    const carouselData = [
        {
            heading: 'AUDIO COMMENTS',
            content: 'Voice comments in screen recordings for smarter collaboration'
        },
        {
            heading: 'REPORT BUGS',
            content: 'Report bugs in seconds with BugZ and Screen Recording'
        },
        {
            heading: 'ANNOTATE DOCS AND IMAGES',
            content: 'Capture & Share Images, Videos and Documents in Real Time'
        },
        {
            heading: 'SAVE MULTIPLE ITEMS',
            content: 'Save multiple things on DoBar Clipboard for quick accessibility'
        },
        {
            heading: 'EFFORTLESS SHARING',
            content: 'Share screenshots in just one click'
        }
    ]

    const whatMakesData = {
        heading: 'DOBAR',
        normal: [
            "Complex process for recording screens",
            "No way to indicate changes/suggestions in screenshots and recordings",
            "Opening several applications to share suggestions",
            "One thing can be copied at one time"
        ],
        digital: [
            {
                icon: item1,
                content: "One-stop for all accessibility needs"
            },
            {
                icon: item2,
                content: "Take a screenshot and share it in one click "
            },
            {
                icon: item3,
                content: "Suggest changes and errors with draw tool and voice addition in screenshots and screen recordings."
            },
            {
                icon: item4,
                content: "Copy multiple things on clipboard to avoid switching screen several times"
            }
        ]
    }

    return (
        <AnimatedPage>
            <div className='d-b-page-continaer'>
            <TopQuaterCircle style={{maxWidth: '800px'}}/>
            <a href='/enterprice' className='backicon-dobar'><BackIconTD /></a>
                <div className='d-b-banner-contianer'>
                    <div className='d-b-banner-width-center'>
                        <div className='d-b-banner-image-contianer'>
                            <img src={DoBarBannerImage} className='d-b-banner-image' alt='banner image' />
                        </div>
                        <div className='d-b-banner-heading-contaner'>
                            <h3 className='product-page-name-heading'>DOBAR</h3>
                            <h1 className='pageHeading'>Tools that assist <br /> every task</h1>
                            <ThemeButton style={{ marginTop: '20px' }} heading="EXPERIENCE" />
                            <div className='d-b-banner-icon-contianer'>
                                <img src={dbBannerIcon} className='d-b-banner-icon' alt='banner icon' />
                            </div>
                            <ProductText />
                            <img src={Arrow} className='dobar-arrow' alt='arrow' />
                        </div>
                    </div>
                </div>
                
                <div className='d-b-sec-2-contianer'>
                    <div className='d-b-sec-2-heading-continaer'>
                        <h3 className='pageSubHeading'>WORK BETTER<br /> AND SMARTER WITH DOBAR THAT GETS THINGS DONE IN A JIFFY.</h3>
                        <p className='pageSmallText'>
                            What if you never had to switch screens or learn complex keyboard shortcuts to perform
                            basic functions like taking a screenshot? That's the kind of simplicity and convenience
                            we plan on harboring for every knowledge worker out there. Collaborative working has never
                            been easier with tools like annotations and sticky notes for comments.
                        </p>
                    </div>
                    <div className='d-b-sec-2-image-container'>
                        <img src={DoBarSec2Image} className='d-b-sec-2-image' alt='banner image' />
                    </div>
                </div>
                <CarouselSlidesContainer data={carouselData} />
                <WhatMakesComponent data={whatMakesData} />
                <VideoPlayerComponent heading={"DOBAR"}/>
                <FooterComponent />
            </div>
        </AnimatedPage>
    )
}

export default DoBar
import React from 'react';
import { useNavigate } from 'react-router-dom';

import './EnterpriceCardDetailsCard.css';

import ThemeButton from '../ThemeButton/ThemeButton';


function EnterpriceCardDetailsCard({ data }) {

    const navigate = useNavigate();

    const onButtonClick = (path) => {
        navigate(path);
    }

    return (
        <div className='card-details-card-container'>
            <div className='card-details-card-heading-container'>
                <img className='card-details-card-heading-icon' alt='caht-image' src={data.icon} />
                <div>
                    <h4 className='card-details-card-heading'>{data.heading}</h4>
                    <p className='card-details-card-subheading'>{data.subHeading}</p>
                </div>
            </div>
            <p className='card-details-card-discription'>{data.content}</p>
            <ThemeButton heading="EXPLORE" onClick={() => onButtonClick(data.path)} />
        </div>
    )
}

export default EnterpriceCardDetailsCard
import React, { useState } from "react";

import './SocialWorkspace.css';
import Arrow from '../../assets/images/DownArrow.png';
import ComputerImage from '../../assets/images/ComputerWithCircle2.png'
import Sun from '../../assets/images/sun.png'
import Umbrella from '../../assets/images/umbrella.png'
import SocialStudy from '../../assets/images/socialstudy.png'

import SecationView from "../../components/SectionView/SecationView";
import SelectOptionComponent from "../../components/SelectOptionComponent/SelectOptionComponent";
import AnimatedPage from "../AnimatedPage";
import TopLeftQuaterCircle from "../../components/TopLeftQuaterCircle/TopLeftQuaterCircle";
import TopLeftQuaterCircleSW from "../../components/TopLeftQuaterCircleSW/TopLeftQuaterCircleSW";
import BackIcon from "../../components/BackIcon/BackIcon";
import DotConnectionLeft from "../../components/DotConnectionLeft/DotConnectionLeft";
import ProductText from "../../components/ProductText/ProductText";
import TopQuaterCircleSACC from "../../components/TopQuaterCircleSACC/TopQuaterCircleSACC";
import DotImage from '../../assets/images/image12.png';
import BottomQuaterCircleSocial from "../../components/BottomQuaterCircleSocial/BottomQuaterCircleSocial";

const SocialWorkspace = (props) => {

    const options = [
        {
            heading: "How?",
            subtext: "See how out apps work",
            link: ''
        },
        {
            heading: "Learn More",
            subtext: `about what’s happening at Digital Harbor?`,
            link: ''
        }
    ]

    const weatherData = [
        {
            location: 'HOUSTON, TX',
            weather: 'MOSTLY SUNNY',
            status: 'LOW CHANCE OF RAIN'
        },
        {
            location: 'MUMBAI',
            weather: 'MOSTLY SUNNY',
            status: 'LOW CHANCE OF RAIN'
        },
        {
            location: 'DELHI',
            weather: 'MOSTLY SUNNY',
            status: 'LOW CHANCE OF RAIN'
        }
    ]

    const [activeWeatherEle, setActiveWeatherEle] = useState(0)
    const [weatherDataShow, setWeatherDataShow] = useState(weatherData[activeWeatherEle]);

    const clickOnWeather = () => {
        console.log("the active element is", activeWeatherEle)
        if (activeWeatherEle < weatherData.length - 1) {
            setActiveWeatherEle(activeWeatherEle + 1);
            setWeatherDataShow(weatherData[activeWeatherEle + 1])
        } else {
            setActiveWeatherEle(0);
            setWeatherDataShow(weatherData[0])
        }
    }

    return (
        <AnimatedPage>
            <div className="social-component-container">
            <TopLeftQuaterCircleSW />
            <TopQuaterCircleSACC />
            <DotConnectionLeft />
                <SecationView>
                <a href='/howwe'><BackIcon /></a>
                    <div className="why-we-heading sw-heading">
                        <h1 className="pageHeading sw-head">SOCIAL ENTERPRISE</h1>
                        <h1 className="pageHeading sw-head">APPLICATIONS ARE</h1>
                        <h1 className="pageHeading sw-head">BUILT AROUND THE</h1>
                        <h1 className="pageHeading sw-head">USER</h1>
                    </div>
                    <div className="why-we-heading mv-sw-heading">
                        <h1 className="pageHeading">SOCIAL </h1>
                        <h1 className="pageHeading">ENTERPRISE</h1>
                        <h1 className="pageHeading">APPLICATIONS </h1>
                        <h1 className="pageHeading">ARE BUILT</h1>
                        <h1 className="pageHeading">AROUND THE</h1>
                        <h1 className="pageHeading">USER</h1>
                    </div>
                    <div className="why-we-content-text mv-sw-para">
                        <p className="pageSmallText sw-para">
                        Consumer apps are leagues ahead in offering their users <br /> 
                        extremely personalized experiences. So why don’t<br /> enterpriseapplications offer the same?
                        </p>
                    </div>
                    <div className="why-we-content-text mv-para">
                        <p className="pageSmallText mvpara">
                        You are missing enterprise applications focused on you and your needs.
                        </p>
                        <p className="pageSmallText mvpara2">
                        Consumer apps are leagues ahead in offering their users  extremely personalized experiences. So why don’t enterprise applications offer the same?
                        </p>
                    </div>
                    <ProductText />
                    <img className="down-arrow-image local-down-arrow-image-class" src={Arrow} />
                </SecationView>
                <img className="dot-halfimage whywe-dot" src={DotImage} />
                <SecationView>
                    <h3 className="pageSubHeading section-subheading">DIGITAL HARBOR IS REIMAGINING <br /> ENTERPRISE APPLICATIONS TO <br /> MAKE THEM SOCIAL.</h3>
                </SecationView>

                <div className='heading-with-image-container'>
                    <div className='sec-heading-container2'>
                        <h3 className="pageHeading">WHAT IS A <br /> ‘SOCIAL’ APP?</h3>
                        <p className="pageSmallText sec3-subtext-container">
                            Our social enterprise applications transform data-centric
                            functionality into user-centric immersive experiences; data
                            records are transformed into transaction stories; analytics is integrated in the form of suggestions and
                            predictions directly into the apps;
                            user can search anything, anywhere within the app itself (limited only by security policies).
                        </p>
                        <p className="pageSmallText sec3-desc-container">With social enterprise applications, the user can finally connect dots across transactions!</p>
                    </div>

                    <div className='sec-image-container'>
                        <img alt='ComputerImage' className='computer-image-container2' src={ComputerImage} />
                    </div>
                </div>

                <SecationView>
                    <div onClick={clickOnWeather} className="social-sec-4-container">
                        <p className="top-p">social technology gives you relavant information based on what it knows about you.</p>
                        <p className="top-p mv-top-para">social technology gives </p>
                        <p className="top-p mv-top-para">you relavant information </p>
                        <p className="top-p mv-top-para">based on what it knows</p>
                        <p className="top-p mv-top-para">about you.</p>
                        <h1 className="pageHeading middle-heading mv-middle-head">
                            LIKE KNOWING THAT THE WEATHER <span className="weather-heading-text-decoration">{weatherDataShow.location}</span> <br />
                            IS <span><img className="small-assets-heading" src={Sun} alt="sun" /></span>
                            <span className="weather-heading-text-decoration">{weatherDataShow.weather}</span> WITH
                            <br /> A  <span><img className="small-assets-heading" src={Umbrella} alt="sun" /></span>
                            <span className="weather-heading-text-decoration">{weatherDataShow.status}</span>
                        </h1>
                        <div className="bottom-p-container">
                            <div className="bottom-p-container-text">
                                <p className="bottom-p">so we’d recommend <span className="weather-heading-text-decoration">going out for a stroll and enjoying the great weather!</span></p>
                            </div>
                        </div>
                    </div>
                </SecationView>
                <h1 className="pageHeading animation-head">Studying the<br /> Spheres of Social<br /> Technology</h1>
                {/* <img className="socialstudy" src={SocialStudy} /> */}
                
                <SelectOptionComponent
                    middleHeading={"MEET OUR SOCIAL <br/> PRODUCT SUITES"} options={options}
                />
                <BottomQuaterCircleSocial /> 
            </div>
        </AnimatedPage>
    )
}

export default SocialWorkspace
import React from 'react';
import './AbotUs.css';

import AnimatedPage from '../AnimatedPage';

import SecationView from '../../components/SectionView/SecationView';
import Arrow from '../../assets/images/DownArrow.png';
import Aboutimage from '../../assets/images/aboutUsImage.png';
import HalfCircle from '../../assets/images/Blueelipseto2.png';
import DotRight from '../../assets/images/dotright.png';
import LeftHalf from '../../assets/images/Blueelipsetop3.png';
import { Row, Col } from 'react-bootstrap';
import SelectOptionComponentAbout from '../../components/SelectOptionComponentAbout/SelectOptionComponentAbout';
import TopLeftQuaterCircle from "../../components/TopLeftQuaterCircle/TopLeftQuaterCircle";

import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import DotConnectionLeft from '../../components/DotConnectionLeft/DotConnectionLeft';
import DotConnectionBottomRight from '../../components/DotConnectionBottomRight/DotConnectionBottomRight';
import BackIcon from "../../components/BackIcon/BackIcon";
import ProductText from '../../components/ProductText/ProductText';
import TopQuaterCircleSACC from '../../components/TopQuaterCircleSACC/TopQuaterCircleSACC';
import DotImage from '../../assets/images/image12.png';

function AbotUs() {

    const options = [
        {
            heading: "learn more",
            subtext: "about our products",
            link: '/enterprice'
        }
    ]

    return (
        <AnimatedPage>
            <div className='about-us-contianer'>
            <TopLeftQuaterCircle />
            <TopQuaterCircleSACC />
            <DotConnectionLeft />
                <SecationView className='section-bg-margin-left section-bg-margin-left'>
                <BackIcon />
                    <div className="how-we-heading">
                        <h1 className="pageHeading">
                            INVENTION IS THE <br />
                            BEGINNING OF ANY <br />
                            REVOLUTIONARY STORY, <br />
                            AND THIS IS THE STORY OF <br />
                            DIGITAL HARBOR…
                        </h1>
                    </div>b
                    <div className="how-we-content-text">
                        <div className='how-we-hanglecontext-left'>
                            <img className="down-arrow-image how-local-down-arrow-image-class aboutus-arrow" src={Arrow} />
                        </div>
                    </div>
                    <ProductText />
                </SecationView>

                <div className='abot-sec-2-container'>
                    <div className='about-left-sec2-container'>
                        <img className='about-us-image' src={Aboutimage} alt='AboutImage' />
                    </div>

                    <img className="dot-halfimage about-dot" src={DotImage} />
                    <div className='about-right-sec2-container'>
                        <h1 className='pageSubHeading about-subhead'>
                            25 YEARS BACK, A <br />
                            COUPLE OF I.T. <br /> ENTHUSIASTS DECIDED <br />
                            TO FIND A BETTER WAY <br />
                            TO SCREEN CUSTOMERS <br />
                            FOR THE BANKING <br />
                            INDUSTRY.
                        </h1>
                        <p className='pageSmallText about-right-para about-right-para-top-margin'>
                            Under the leadership of Rohit Agarwal, our Chief Innovation Officer, they built a<br /> solution which is now a leader in risk management solutions for financial<br /> institutions. This solution was coined as ‘Know Your Customer (KYC)’ and has fast<br /> become a norm across industries and regions. </p>
                        <p className='pageSmallText about-right-para'>
                            With the launch of ‘Know Your Provider (KYP)’ Digital Harbor has become one of<br /> the fastest growing companies in Healthcare sector and is already a trusted<br /> partner of leading Healthcare organizations across the United States. The thing<br /> about a revolution is; once it begins it’s impossible to stop. Those who are<br /> inspired, get drawn to it. People who liked to challenge the industry status quo<br /> joined the Digital Harbor bandwagon. The company, driven by a bunch of<br /> passionate revolutionists started to add many world’s firsts to its list including:
                        </p>
                        <div className='scroll-div-para-container'id="scrollbar">
                            <p className='scroll-div-para'>
                                Link Analytics Platform for Intelligence Agencies || Commercial Data Fusion platform for Intelligence Sector || -Smart Client Technology. || -Model-driven SOA Technology. || -Know Your Fraud for Banking Industry’s first Cross-Channel Fraud solution. || -Next-generation Case Management for Investigations Management. || -Know Your Claim for Modeling Solution for Health Care Fraud. || -Know Your Provider for Automated Front-end Fraud Prevention Solution. || -Social Enterprise Technology (SET).
                            </p>
                        </div>
                        <div className='about-right-sec2-arrows-container'>
                            <BsArrowLeft className='about-sec-2-arrows about-sec-2-arrows-left' />
                            <BsArrowRight className='about-sec-2-arrows' />
                        </div>
                    </div>
                </div>

                <div className='lightBlueSecBG about-sec3-top-bottom-padding'>
                    <SecationView className='section-bg-margin-left'>
                        <Row className='aboutblue'>
                            <Col md={6}>
                                <h1 className='pageSubHeading about-col-head'>JUST LIKE IN THE<br /> BEGINNING,<br /> DIGITAL HARBOR<br /> HAS ONCE AGAIN<br /> TAKEN THE ROAD<br /> – NOT LESS<br /> TRAVELLED BUT<br /> – NEVER TAKEN!</h1>
                            </Col>
                            <Col md={6} className='bs-margin-auto aboutus-column'>
                                <p className='about-sec-3-right-heading'>
                                    We are currently reimagining 19<br /> innovative enterprise applications<br /> which will change the way you work!
                                </p>
                                <p className='pageSmallText about-sec-3-right-content'>
                                    You can know all about some of them on this website while we<br /> fine tune the remaining. So, buckle up. We are about to change<br /> everything you know about enterprise applications.
                                </p>
                            </Col>
                        </Row>
                    </SecationView>
                </div>
                <img className="dot-right" src={DotRight} />
                <img className="left-halfimage aboutus-animated" src={LeftHalf} />
                {/* <div className='how-we-banner-animated-image aboutus-animated'></ div> */}
                <img className="right-halfimage" src={HalfCircle} />
                <SelectOptionComponentAbout middleHeading={"MEET OUR SOCIAL <br/> PRODUCT SUITES"} options={options} />

            </div>
        </AnimatedPage>
    )
}

export default AbotUs
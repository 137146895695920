import React, { useState } from 'react';

import './SocialText.css';
import CharacterImageContainer from '../../assets/images/CharacterImageContianer.png';
import socialTextSec2Image from '../../assets/images/socialTextSec2Image.png';
import item1 from '../../assets/images/socialText/item1.png';
import item2 from '../../assets/images/socialText/item2.png';
import item3 from '../../assets/images/socialText/item3.png';
import item4 from '../../assets/images/socialText/item4.png';

import AnimatedPage from '../AnimatedPage';
import KeyboardCompoent from '../../components/KeyboardComponent/KeyboardCompoent';
import ThemeButton from '../../components/ThemeButton/ThemeButton';
import ShadowCardST from '../../components/ShadowCardST/ShadowCardST';
import CarouselSlidesContainer from '../../components/CarouselSlidesContainer/CarouselSlidesContainer';
import WhatMakesComponent from '../../components/WhatMakesComponent/WhatMakesComponent';
import VideoPlayerComponent from '../../components/VideoPlayerComponent/VideoPlayerComponent';
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import TopQuaterCircle from "../../components/TopQuaterCircle/TopQuaterCircle";
import SearchTxtImg from '../../assets/images/Group2954.png';
import BackIcon from "../../components/BackIcon/BackIcon";
import ProductText from '../../components/ProductText/ProductText';
import Circle from '../../assets/images/Blueelipsetop4.png';
import Keyboard from '../../assets/images/Keyboard.png';

const SocialText = () => {

    const [keyboardKey, setKeyboardKey] = useState('');

    const carouselData = [
        {
            heading: 'SELF FORMATTING TEXT',
            content: 'Self-formatting text for actionable communication'
        },
        {
            heading: 'INTEGRATED HYPERMEDIA',
            content: 'Integrated Hypermedia like tags and graphics for better understanding'
        },
        {
            heading: 'COLLABORATE EFFORTLESSLY',
            content: 'Collaborative Versioning within business applications'
        }
    ]

    const whatMakesData = {
        heading: 'SOCIAL TEXT',
        normal: [
            "Basic prompts and data is presented as a collaborative tool ",
            "Unclear communication",
            "Difficult to track tasks"
        ],
        digital: [
            {
                icon: item1,
                content: "Envision data with a Data Card and Graphics"
            },
            {
                icon: item2,
                content: "Empower your forms with the ability to narrate a story via data"
            },
            {
                icon: item3,
                content: "Access relevant information in the blink of an eye"
            },
            {
                icon: item4,
                content: "Exchange details and data Effortlessly. Social and Collaborative experienc"
            }
        ]
    }

    return (
        <AnimatedPage>
            <div className='social-text-page-container'>
            <TopQuaterCircle style={{maxWidth: '700px'}}/>
                <div className='s-t-p-banner-page'>
                    <div className='s-t-benner-heading-container'>
                    <a href='/enterprice'><BackIcon /></a>
                    <h3 className='product-page-name-heading chat'>YOODLE:</h3>
                    <h3 className='product-page-name-heading chat-sub'>COLLABORATION SUITE</h3>
                        <h3 className='product-page-name-heading st-heading'>SOCIAL TEXT</h3>
                        <h1 className='pageHeading st-main-head'>
                            Communication<br />that leads to<br />A
                        </h1>
                        <div className='sc-exp-button'>
                            <ThemeButton style={{ marginTop: '6em' }} heading="EXPERIENCE" />
                        </div>
                        <ProductText />
                        <div className='imageWithCharacterContianer'>
                            <ShadowCardST style={{ padding: '15px 15px'}}>
                                <img className='CharacterImageContainer' src={CharacterImageContainer} alt='image-contianer' />
                                <div className='keyboard-character-container'>
                                    {
                                        keyboardKey
                                    }
                                </div>
                            </ShadowCardST>
                        </div>
                        <p>
                        <img src={Keyboard} className="st-keyboard" />
                        {/* <p className='keyboard-under-title'>START PRESSING KEY ON YOUR KEYBOARD</p> */}
                        </p>
                    </div>
                    
                    <div className='s-t-banner-keyboard-contianer'>
                        <KeyboardCompoent setKeyboardKey={setKeyboardKey} />  
                    </div>
                </div>
                <div className='st-sec-2-heading-contianer'>
                    <h2 className='product-pages-heading sf-scc-2-heading st-head'>
                        HAVE YOU EVER FELT <span className='blue-heading'>JUST</span> <br />
                        <span className='blue-heading'>TEXT IS NOT ENOUGH</span>?
                    </h2>
                    <img className='st-form' src={SearchTxtImg} alt='form' />
                </div>

                <div className='st-sec-2-cotianer'>
                <img src={Circle} className='st-cricle' alt='st-circle' />
                    <div className='st-sec-2-left-container'>
                        <img src={socialTextSec2Image} className='ss-sec-2-left-image' alt='ss-sec-2-left-image' />
                    </div>
                    <div className='st-sec-2-right-container'>
                        <div className='st-sec-2-right-content'>
                            <h1 className='pageSubHeading st-subhead'>SAY GOODBYE TO PLAIN & BORING TEXT</h1>
                            <p className='pageSmallText st-sec-2-para-contianer'>
                                Social Text is an intelligent text that understands the intent and pattern of the knowledge worker and acts accordingly. As you type, it blends and pulls-in all other forms of media (i.e - images, videos, documents, data and weblinks) to transform your text into an understandable, collaborative and actionable business conversation. 
                            </p>
                        </div>
                    </div>
                </div>
                <CarouselSlidesContainer data={carouselData} />
                {/* <WhatMakesComponent data={whatMakesData} /> */}
                <VideoPlayerComponent heading={"SOCIAL TEXT"}/>
                <FooterComponent />
                <img src={Circle} className='st-footer-cricle' alt='st-footer-circle' />
            </div>
        </AnimatedPage>
    )
}

export default SocialText
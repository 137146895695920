import React from 'react';

import './Logo.css';
import logoIcon from '../../assets/images/Group2571.png';

const Logo = (props) => {
    return (
        <div className='banner-logo-half-cicle-imgae-contianer'>
            <img style={props.style} className='banner-logo-half-cicle-imgae' alt='upper' src={logoIcon} />
        </div>
    )
}

export default Logo
import React, { useState } from "react";

import { FaTimes, FaBars } from "react-icons/fa";
import './baseScreenContaienr.css';
import sidebarlogo from '../assets/images/sidebarLogo.svg';
import soundPlayImage from '../assets/images/soundPlayImage.png';
import soundPlayImage2 from '../assets/images/audiooff.png';
import colorIconDh from '../assets/images/colorIconDh.png';

import SoundFile from '../assets/sound/sound_track.mp3';

import ScreenRouter from '../router/ScreenRouter';

const BaseScreenContainer = () => {

    const [collapsedStatus, setCollapsedStatus] = useState({});
    const [sideNavActive, setSidenavActive] = useState(false);
    const [mobilePanel, setMobilePanel] = useState({ height: '0vh' })
    const [soundon, setSoundOn]=useState(false);
   

    // const sound = new Audio(SoundFile);
    const [sound] = useState(new Audio(SoundFile))
    const openSidebar = () => {
        setCollapsedStatus({ width: 280 })
        setSidenavActive(true)
    }

    const closeSidebar = () => {
        setCollapsedStatus({ width: 0 })
        setSidenavActive(false)
    }
    
    const toggleSoundPlay = () => {
        if(soundon) sound.pause();
        else sound.play();
        setSoundOn(!soundon)
    }
    
    

    const toggleMobileTopPanel = () => {
        if (mobilePanel.height == '0vh') {
            setMobilePanel({ height: '100vh' })
        } else {
            setMobilePanel({ height: '0vh' })
        }
    }

    return (
        <div className="main-base-screen-container" >

            <div style={collapsedStatus} className="sidepanel">
                {/* <FaTimes onClick={closeSidebar} className="ham-icon" /> */}
                <div className="sidepanel-content-container">
                    <div className="sidepanel-links-container">
                        <a className="side-panel-link" href="/enterprice">PRODUCTS</a>
                        <a className="side-panel-link" href="/aboutus">ABOUT US</a>
                        <a className="side-panel-link" href="/career">CAREERS</a>
                        <a className="side-panel-link" href="/">RESOURCES</a>
                        <a className="side-panel-link" href="/contactus">CONTACT</a>
                    </div>
                </div>
                <button className="explore-button"><span>EXPLORE SET</span></button>
                <div className="sidebar-labels-container">
                    <div className="label-center-des">
                        <div className="sidebar-label">
                            <p className="label">CHANGE MY EXPERIENCE</p>
                            <span className="label-gradient"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={sideNavActive ? 'sidenav-open' : 'sidenav-closed'}>
                <div className="ham-container">
                    {
                        collapsedStatus.width ? collapsedStatus.width == 0 ? <FaBars onClick={openSidebar} className='ham-icon' /> :
                            <FaTimes onClick={closeSidebar} className="ham-icon ham-icon-black" /> : <FaBars onClick={openSidebar} className='ham-icon' />
                    }
                </div>

                <div className="sidebar-logo-container">
                    <img className={sideNavActive ? 'sidebar-logo sidebar-logo-black' : 'sidebar-logo'} src={sidebarlogo} />
                </div>

                <div className="sidebar-labels-container">
                    <div className="label-center">
                        <div className="sidebar-label">
                            <p className="label">EXPLORE SET</p>
                            <span className="label-gradient"></span>
                        </div>
                    </div>
                    <span className='sidebar-labels-dot isplay-inline' />
                    <div className="label-center">
                        <div className="sidebar-label">
                            <p className="label">BUSINESS</p>
                            <p className="label">EXPERIENCE</p>
                            <span className="label-gradient"></span>
                            <p className="label-subtext-type">view our explorer experience</p>
                        </div>
                    </div>
                    <span className='sidebar-labels-dot isplay-inline' />
                </div>
            </div>

            <div className="mobile-top-nav-bar">
                <FaBars onClick={() => toggleMobileTopPanel()} className='mobile-ham-icon' />
                <img className="mobile-header-icon" src={sidebarlogo} />
            </div>

            <div style={mobilePanel} className="top-panel">
                <div className="m-top-panel-contianer">
                    <div className="m-t-p-header-contianer">
                        <FaTimes onClick={() => toggleMobileTopPanel()} className="cross-icon ham-icon-black" />
                        <div className="base-page-top-sound-contianer-m">
                            <p className="base-page-top-sound-contianer-text">AUDIO ON</p>
                            <img className="base-page-top-sound-contianer-img" src={soundPlayImage} onClick={toggleSoundPlay}/>
                        </div>
                    </div>
                    <div className="m-t-p-body-flex-container">
                        <div className="m-t-p-body-flex">
                            <img className="m-t-p-icon" src={colorIconDh} />
                            <div className="m-t-p-links-contianer">
                                <a className="m-t-p-link" href="/enterprice">PRODUCTS</a>
                                <a className="m-t-p-link" href="/aboutus">ABOUT US</a>
                                <a className="m-t-p-link" href="/career">CAREERS</a>
                                <a className="m-t-p-link" href="/">RESOURCES</a>
                                <a className="m-t-p-link" href="/contactus">CONTACT</a>
                            </div>
                            <button className="explore-button"><span>EXPLORE SET</span></button>
                            <div className="m-t-p-g-text">
                                <p className="m-t-p-g-text-p">CHANGE MY EXPERIENCE</p>
                                <span className="label-gradient"></span>
                            </div>
                        </div>
                    </div>
                    <div className="m-t-p-bottom-iland"></div>
                </div>
            </div>

            <div className="base-screen-container">
                <div className="base-page-top-sound-contianer">
                    {
                        soundon ? 
                        <>
                        <span className="base-page-top-sound-contianer-text">AUDIO OFF</span>
                        <img src={soundPlayImage2} onClick={toggleSoundPlay}/></>
                          : 
                        <>
                        <span className="base-page-top-sound-contianer-text">AUDIO ON</span>
                        <img className="base-page-top-sound-contianer-img" src={soundPlayImage} onClick={toggleSoundPlay} />
                        </> 
                    }
                </div>
                <ScreenRouter />
            </div>

        </div>
    )
}
export default BaseScreenContainer
import React, { useState, useRef } from 'react';

import './GetInTouchSection.css';
import contactImage from '../../assets/images/contactImage.png';
import blackUserConCard from '../../assets/images/blackUserConCard.png';

import SecationView from '../SectionView/SecationView';
import ThemeButton from '../ThemeButton/ThemeButton';

import { BiPhone } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";


const GetInTouchSection = (props) => {

    const steps = [
        {
            heading: 'NAME',
            key: 'FirstName',
            placeholder: 'First Name'
        },
        {
            heading: 'JOB TITLE',
            key: 'jobTitle',
            placeholder: 'Job Title'
        },
        {
            heading: 'EMAIL',
            key: 'email',
            placeholder: 'Email'
        },
        {
            heading: 'PH. NUMBER',
            key: 'phone',
            placeholder: 'Phone Number'
        }
    ]

    const [activeState, setActiveState] = useState(0);
    const [getTouchDone, setGetTouchDone] = useState(false);
    const [activeParent, setActiveParent] = useState(0);

    const [formData, setFormData] = useState({
        FirstName: '',
        lastName: '',
        jobTitle: '',
        email: '',
        phone: '',
    })

    const setValusToForm = (event, objKey) => {
        setFormData({ ...formData, [objKey]: event.target.value })
    }

    const handleEnterPress = (e, i) => {
        console.log("enter value is", e.key)
        if (e.key === 'Enter' || e.key === 'Tab') {
            if ((i + 1) < steps.length) {
                setActiveState(i + 1);
            } else {
                toggleGetInTouch();
                setActiveState();
            }
        }
    }

    const toggleGetInTouch = () => {
        setGetTouchDone(!getTouchDone)
    }

    return (
        <div className='blueSecBG section-bg-margin-left sf-sec-circle'>
            <SecationView className='get-in-touch-section-custom career-getintouch'>

                <div className='contact-get-touch-heading'>
                    <img alt='contact-iamge' src={contactImage} />
                    <h1 className='pageSubHeading contact-us-head-section-heading'>
                        {
                            !formData.FirstName ? props.heading :
                                <span>THANKS FOR GETTING IN TOUCH
                                    <span className='get-touch-name-container'>
                                        {" " + formData.FirstName}
                                    </span>!
                                </span>
                        }
                    </h1>
                </div>


                <div className='parent-selector-contianer'>

                    <div className='parent-options-contianer'>
                        <div className='parent-option-contianer' onClick={() => setActiveParent(0)}>
                            <div className='parent-outer-circle outer-circle'>
                                <span className={'inner-circle ' + (activeParent == 0 ? 'inner-parent-circle-selected' : '')}></span>
                            </div>
                            <div className={'step-label-contianer ' + (activeParent == 0 ? 'step-label-contianer-bottom-border' : '')}>
                                PERSONAL INFO
                            </div>
                        </div>
                        <div className='parent-option-contianer' onClick={() => setActiveParent(1)}>
                            <div className='parent-outer-circle outer-circle'>
                                <span className={'inner-circle ' + (activeParent == 1 ? 'inner-parent-circle-selected' : '')}></span>
                            </div>
                            <div className={'step-label-contianer ' + (activeParent == 1 ? 'step-label-contianer-bottom-border' : '')}>
                                YOUR MESSAGE
                            </div>
                        </div>
                    </div>

                    {
                        activeParent == 0 ?
                            <div className='contact-sections-container'>
                                <div className='stepper-contianer'>
                                    {
                                        steps.map((e, i) => {
                                            return (
                                                <div key={i}>
                                                    <div className='stepper-step-content-container'>
                                                        <div className='step-label-contianer'>
                                                            {e.heading}
                                                        </div>
                                                        <div className='radio-and-line-continaer'>
                                                            <div className='step-icon-contianer'>
                                                                <div className='outer-circle' onClick={() => setActiveState(i)}>
                                                                    <span className={
                                                                        activeState == i ? 'inner-circle inner-circle-selected' :
                                                                            formData[e.key] ? 'inner-circle inner-circle-has-data' : 'inner-circle '
                                                                    }></span>
                                                                </div>
                                                            </div>
                                                            {
                                                                i < steps.length - 1 ? <div className='between-stepper-steps'></div> : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className='container-text-content-container'>
                                    {
                                        !getTouchDone ?
                                            <div className='constact-text-fields-container'>

                                                <input
                                                    className='contact-info-contianer active-state-text-field'
                                                    placeholder={steps[activeState].placeholder}
                                                    onChange={e => setValusToForm(e, steps[activeState].key)}
                                                    value={formData[steps[activeState].key]}
                                                    onKeyDown={e => handleEnterPress(e, activeState)}
                                                    type={steps[activeState].key == 'phone' ? 'number' : 'text'}
                                                />

                                            </div> :
                                            <div className='get-in-touch-card-view-container'>
                                                <img className='contact-user-card-image' src={blackUserConCard} alt="user-image" />
                                                <div className='user-prifile-details-cotnainer'>
                                                    <h3 className='contact-user-profile-card-username'>
                                                        {formData.FirstName + " " + formData.lastName}
                                                    </h3>
                                                    <span className='contact-user-profile-card-jobtitle'>{formData.jobTitle}</span>
                                                    <div className='contact-user-profile-card-contact-container'>

                                                        <div className='address-element-container'>
                                                            <AiOutlineMail className='address-element-icon' />
                                                            <p className='address-element-content'>
                                                                {formData.email}
                                                            </p>
                                                        </div>

                                                        <div className='address-element-container'>
                                                            <BiPhone className='address-element-icon' />
                                                            <p className='address-element-content'>
                                                                {formData.phone}
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div> :
                            <div className='message-screen-contianer'>
                                <textarea className='message-text-field-container' placeholder='TYPE YOUR MESSAGE HERE' rows="7" cols="50">
                                </textarea>
                                <ThemeButton style={{marginTop: '20px'}} heading={"GET IN TOUCH"}/>
                            </div>
                    }

                </div>
            </SecationView>
        </div>
    );
}

export default GetInTouchSection
import React from 'react';

import './EnterpriceCardComponent.css';
import bars from '../../assets/images/enterpriceCardBars.png';

import LottiePlayer from '../LottiePlayer/LottiePlayer';

function EnterpriceCardComponent({ data, onClick }) {
    return (
        <div onClick={onClick} className={data.background + ' enterprice-card-container'}>
            <h3 className='heading-enterprice-card'>
                {
                    data.heading
                }
            </h3>
            <p className='enterprice-card-subheading'>
                {
                    data.subheading
                }
            </p>
            <p className='entrprice-card-subtext'>
                {
                    data.subtext
                }
            </p>

            <div className='enterprice-card-images-container'>
                <div>
                <img className='enterprice-card-3-bars-image' src={bars} />
                </div>
                {
                    data.animation ? <LottiePlayer animation={data.animation} /> : ''
                }
                {/* <img src={data.image} className='enterprice-main-bg' /> */}
            </div>
        </div>
    )
}

export default EnterpriceCardComponent
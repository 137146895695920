import React, { useRef, useState } from 'react';
import Slider from "react-slick";

import './CarouselSlidesContainer.css';
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

import ShadowCard from '../ShadowCard/ShadowCard';

const CarouselSlidesContainer = ({ data }) => {
    const sliderRef = useRef();

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1
    };

    const handleNextActiveIndex = () => {
        sliderRef.current.slickNext()
    }

    const handlePrewActiveIndex = () => {
        sliderRef.current.slickPrev()
    }


    return (
        <div className='carousel-component-contianer'>
            <div className='carousel-component-inner'>
                <div className='carousel-left-continaer'>
                <h1 className='pageHeading what-makes-heading st-title'>WHAT MAKES SOCIAL TEXT<br /><span className='wow-contianer st-wow'>WOW</span><span className='st-wow'>?</span></h1>
                </div>
                <div className='carousel-contianer'>
                    <Slider ref={sliderRef} {...settings}>

                        {
                            data.map(e => {
                                return (
                                    <div key={e.heading} className='carosel-item-container'>
                                        <ShadowCard style={{ width: '100%' }}>
                                            <div className='carosel-item-content-container'>
                                                <h3 className='carosel-item-heading'>
                                                    {e.heading}
                                                </h3>
                                                <p className='carosel-item-content'>
                                                    {e.content}
                                                </p>
                                            </div>
                                        </ShadowCard>
                                    </div>
                                )
                            })
                        }

                    </Slider>
                    <div className='carosel-slide-change-container'>
                        <BsArrowLeft onClick={handlePrewActiveIndex} className='carousel-arrow-icon left-arrow-carousel' />
                        <BsArrowRight onClick={handleNextActiveIndex} className='carousel-arrow-icon ' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarouselSlidesContainer
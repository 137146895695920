import React from 'react';

import { Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from 'framer-motion'

import Home from '../screens/Home/Home';
import WhyWe from "../screens/WhyWe/WhyWe";
import HowWe from "../screens/HowWe/HowWe";
import SocialWorkspace from "../screens/SocialWorkspace/SocialWorkspace";
import EnterPriceSolutions from '../screens/EnterpriceSolutions/EnterPriceSolutions';
import AbotUs from '../screens/AboutUs/AbotUs';
import ContactUs from '../screens/ContactUs/ContactUs';
import SocialText from '../screens/SocialText/SocialText';
import SocialChat from '../screens/SocialChat/SocialChat';
import SocialForms from '../screens/SocialForms/SocialForms';
import SocialSearch from '../screens/SocialSearch/SocialSearch';
import Tap from '../screens/Tap/Tap';
import DoBar from '../screens/DoBar/DoBar';
import Careers from '../screens/Careers/Careers';

function AnimatedRouter() {
    const location = useLocation()
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/whywe" element={<WhyWe />} />
                <Route path="/howwe" element={<HowWe />} />
                <Route path="/social" element={<SocialWorkspace />} />
                <Route path="/enterprice" element={<EnterPriceSolutions />} />
                <Route path="/aboutus" element={<AbotUs />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/socialtext" element={<SocialText />} />
                <Route path='/socialchat' element={<SocialChat />} />
                <Route path='/socialform' element={<SocialForms />} />
                <Route path='/socialsearch' element={<SocialSearch />} />
                <Route path='/tap' element={<Tap />} />
                <Route path='/dobar' element={<DoBar />} />
                <Route path='/Career' element={<Careers />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRouter
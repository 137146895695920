import React from "react";
import BaseScreenContainer from './screens/BaseScreenContainer';

import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  return (
    <>
      <BaseScreenContainer />
    </>
  )

}
export default App
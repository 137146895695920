import React from "react";

import './ShadowCard.css'

const ShadowCard = (props) => {
    return (
        <div style={props.style} className={props.className + " shadow-box-border-wraper"}>
            {
                props.children
            }
        </div>
    )
}

export default ShadowCard
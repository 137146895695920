import React from 'react';
import './FooterComponent.css';

import footerLogo from '../../assets/images/footerLogo.png';

const FooterComponent = () => {
    return (
        <div className='section-bg-margin-left'>
            <div className='footer-contianer'>
                <div className='footer-sections-contianer'>
                    <div className='footer-company-detials-container'>
                        <img className='footer-logo-image' alt='footer-logo' src={footerLogo} />
                        <p className='footer-company-address-details'>
                            Digital Harbor, Inc.<br />
                            1775 Greensboro Station Place, Suite 105 <br />
                            McLean, VA 22102 <br />
                        </p>
                        <p className='footer-company-address-details'>
                            Phone: 703 - 635 – 3477<br />
                            innovations@dharbor.com
                        </p>
                    </div>
                    <div className='footer-links-contianer'>
                        <div className='footer-left-links-container'>
                            <a className='footer-link' href='/'>Home</a>
                            <a className='footer-link' href='/aboutus'>About Us</a>
                            <a className='footer-link' href='/'>Careers</a>
                            <a className='footer-link exp-footer' href='/'>Change my<br /> Experience</a>
                        </div>
                        <div className='footer-right-links-container'>
                            <a className='footer-link' href='/'>Products</a>
                            <a className='footer-link' href='/'>Explore SET</a>
                            <a className='footer-link' href='/contactus'>Contact Us</a>
                        </div>
                    </div>
                </div>
                <div className='footer-bottom-container'>
                    <p className='footer-bottom-text'>
                        Copyright © 2022 Digital Harbor, Inc. All Rights Reserved
                    </p>
                </div>
            </div>

            <div className='footer-contianer-mobile'>
            <img className='footer-logo-image' alt='footer-logo' src={footerLogo} />
                <div className='footer-sections-contianer'>               
                    <div className='footer-company-detials-container'>                        
                        <p className='footer-company-address-details'>
                            Digital Harbor, Inc.<br />
                            1775 Greensboro Station Place, Suite 105 <br />
                            McLean, VA 22102 <br />
                        </p>
                        <p className='footer-company-address-details'>
                            Phone: 703 - 635 – 3477<br />
                            innovations@dharbor.com
                        </p>
                    </div>
                    <div className='footer-links-contianer'>
                        <div className='footer-left-links-container'>
                            <a className='footer-link' href='/'>Home</a>
                            <a className='footer-link' href='/aboutus'>About Us</a>
                            <a className='footer-link' href='/'>Careers</a>
                            <a className='footer-link' href='/'>Change my<br /> Experience</a>
                        </div>
                        <div className='footer-right-links-container'>
                            <a className='footer-link' href='/'>Products</a>
                            <a className='footer-link' href='/'>Explore SET</a>
                            <a className='footer-link' href='/contactus'>Contact Us</a>
                        </div>
                    </div>
                </div>
                <div className='footer-bottom-container'>
                    <p className='footer-bottom-text'>
                        Copyright © 2022 Digital Harbor, Inc. All Rights Reserved
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FooterComponent
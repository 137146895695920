import React from 'react';

import './ProductText.css';

const ProductText = (props) => {
    return (
        <div className='product'>
            <a href='/enterprice'><h1 className='product-text'>PRODUCTS</h1></a> 
        </div>
    )
}

export default ProductText
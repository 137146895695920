import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import GetInTouchSectionPopup from "../GetInTouchSectionPopup/GetInTouchSectionPopup";
import "./CareersTeamCard.css";
import team1 from "../../assets/images/team1.png";
import team2 from "../../assets/images/team2.png";
import team3 from "../../assets/images/team3.png";
import team4 from "../../assets/images/team4.png";
import team5 from "../../assets/images/team5.png";
import team6 from "../../assets/images/team6.png";
const { useState, useEffect } = React;

const CareersTeamCard = () => {
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content">
          <GetInTouchSectionPopup />
          <Button onClick={props.onHide}>Close</Button>
        </div>
      </Modal>
    );
  }

  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
    <div className="career-teamcard">
      <div className="carrer-teamcard-component">
        <div className="cc-teamcard-left-container">
          <img src={team1} className="career-teamimage" />
        </div>
        <div className="cc-teamcard-button">
          <div className="cc-teamcard-right-container">
            <h3 className="cc-teamoverall-text firstheading">
              ENGINEERING &<br /> TECH
            </h3>
            <p className="cc-teamoverall-text cc-teamoverall-text-para">
              Develop the products and
              <br /> tools of the future for
              <br /> billions of users.
            </p>
            <button className="cc-viewall-button">LEARN MORE</button>
          </div>
        </div>
      </div>
      <div className="carrer-teamcard-component">
        <div className="cc-teamcard-left-container">
          <img src={team2} className="career-teamimage" />
        </div>
        <div className="cc-teamcard-button">
          <div className="cc-teamcard-right-container">
            <h3 className="cc-teamoverall-text firstheading">
              SALES & SUPPORT
            </h3>
            <p className="cc-teamoverall-text cc-teamoverall-text-para">
              Equip businesses with the
              <br /> right tools to help them
              <br /> grow.
            </p>
            <button className="cc-viewall-button">LEARN MORE</button>
          </div>
        </div>
      </div>
    </div>

    <div className="career-teamcard teamcard-3">
        <div className="carrer-teamcard-component">
        <div className="cc-teamcard-left-container">
            <img src={team3} className="career-teamimage" />
        </div>
        <div className="cc-teamcard-button">
            <div className="cc-teamcard-right-container">
            <h3 className="cc-teamoverall-text firstheading">
                FINANCE
            </h3>
            <p className="cc-teamoverall-text cc-teamoverall-text-para">
                Design and manage<br /> solutions to support our<br /> global business.
            </p>
            <button className="cc-viewall-button">LEARN MORE</button>
            </div>
        </div>
        </div>
        <div className="carrer-teamcard-component">
        <div className="cc-teamcard-left-container">
            <img src={team4} className="career-teamimage" />
        </div>
        <div className="cc-teamcard-button">
            <div className="cc-teamcard-right-container">
            <h3 className="cc-teamoverall-text firstheading">
                BUSINESS STRATEGY
            </h3>
            <p className="cc-teamoverall-text cc-teamoverall-text-para">
                Deliver the analysis and<br /> insights we use to<br /> innovate.
            </p>
            <button className="cc-viewall-button">LEARN MORE</button>
            </div>
        </div>
        </div>
    </div>

    <div className="career-teamcard teamcard-3">
        <div className="carrer-teamcard-component">
        <div className="cc-teamcard-left-container">
            <img src={team5} className="career-teamimage" />
        </div>
        <div className="cc-teamcard-button">
            <div className="cc-teamcard-right-container">
            <h3 className="cc-teamoverall-text firstheading">
                MARKETING & COMMUNICATION
            </h3>
            <p className="cc-teamoverall-text cc-teamoverall-text-para">
                Equip businesses with the<br /> right tools to help them<br /> grow.
            </p>
            <button className="cc-viewall-button">LEARN MORE</button>
            </div>
        </div>
        </div>
        <div className="carrer-teamcard-component">
        <div className="cc-teamcard-left-container">
            <img src={team6} className="career-teamimage" />
        </div>
        <div className="cc-teamcard-button">
            <div className="cc-teamcard-right-container">
            <h3 className="cc-teamoverall-text firstheading">
                DESIGN
            </h3>
            <p className="cc-teamoverall-text cc-teamoverall-text-para">
                Create beautiful and<br /> intuitive experiences.
            </p>
            <button className="cc-viewall-button">LEARN MORE</button>
            </div>
        </div>
        </div>
    </div>
  </>
    
  );
};

export default CareersTeamCard;

import React from 'react';

import './WhatMakesComponent.css';

const WhatMakesComponent = ({ data }) => {
    return (
        <div className='what-makes-c-container'>
            <div className='what-makes-c-content'>
                <h1 className='pageHeading what-makes-heading'>WHAT MAKES <br /> {data.heading} <span className='wow-contianer'>WOW</span>?</h1>
                <div className='what-makes-points-contianer'>
                    <div className='what-makes-left-points-contianer'>
                        <p className='what-makes-content-heading pageSmallText'>Regular Solutions</p>
                        <ul className='what-makes-left-list'>
                            {
                                data.normal.map((e, i) => {
                                    return (
                                        <li key={i} className='what-makes-left-list-item'>{e}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className='what-makes-right-points-contianer'>
                        <p className='what-makes-content-heading pageSmallText'>Digital Harbor’s Solutions</p>
                        {
                            data.digital.map((e, i) => {
                                return (
                                    <div key={i} className='what-makes-dh-item-contianer'>
                                        <img src={e.icon} className='dh-item-icon' alt='dh-item-container' />
                                        <p className='dh-item-text-p'>{e.content}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatMakesComponent
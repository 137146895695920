import React from "react";
import './SecationView.css'

const SecationView = (props) => {
    return (
        <div className={props.className}>
            <div style={props.style} className={"section-view-container"}  >
                {
                    props.children
                }
            </div>
            
        </div>
    )
}

export default SecationView
import React from "react";

import './OptionComponent.css'
import OptionCircle from '../../assets/images/OptionCircle.png'
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";

const OptionComponent = (props) => {
    return (
        // <div className="options-container">
        //     {
        //         props.optionData.length > 0 ?
        //             props.optionData.map((e, i) => {
        //                 return (
        //                     <Link key={i} to={e.link} className="option-container">
        //                         <img className="option-circle-image" src={OptionCircle} />
        //                         <div className="option-content-contanier">
        //                             <p className="option-heading"><em>{e.heading}</em></p>
        //                             <p className="option-subtext">{e.subtext} </p>
        //                         </div>
                               
        //                     </Link>)
        //             }) : ''
        //     }
             
        // </div>

            <div className="options-container">            
                <div className="options-container-description">
                    <a href="/whywe"> <img className="option-circle-image" src={OptionCircle} /></a>
                    <div className="option-content-contanier">
                        <p className="option-heading"><em>Why</em></p>
                        <p className="option-subtext">are we reimagining business apps? </p>
                    </div>
                </div>
                    <Logo />
                <div className="options-container-description">
                    <a href="/howwe"> <img className="option-circle-image" src={OptionCircle} /></a>
                    <div className="option-content-contanier">
                        <p className="option-heading"><em>how</em></p>
                        <p className="option-subtext">are we reimagining business apps? </p>
                    </div>
                </div>
            </div>
    )
}

export default OptionComponent
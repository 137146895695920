import React from 'react';

import './SelectOptionComponentAbout.css';

import OptionComponent from '../OptionComponent/OptionComponent';
import SecationView from '../SectionView/SecationView';
import BottomQuaterCircle from "../BottomQuaterCircle/BottomQuaterCircle";
import DotConnection from '../DotConnection/DotConnection';
import '../OptionComponent/OptionComponent.css'
import OptionCircle from '../../assets/images/OptionCircle.png'

const SelectOptionComponentAbout = (props) => {

    return (
        <SecationView>
            {/* <DotConnection /> */}
            {/* <BottomQuaterCircle />    */}
            {
                props.heading ? <h1 className='pageSubHeading com-heading' dangerouslySetInnerHTML={{ __html: props.heading }}></h1> : ''
            }

            {
                props.middleHeading ? <h1 className='theme-heading center-heading' dangerouslySetInnerHTML={{ __html: props.middleHeading }}></h1> : ''
            }
            
            <div className='sec-option-container'>
            
                {/* <OptionComponent optionData={props.options} /> */}
                <div className="options-container howwe">            
                    <div className="options-container-description opt-howwe">
                        <a href="/"> <img className="option-circle-image" src={OptionCircle} /></a>
                        <div className="option-content-contanier">
                            <p className="option-heading"><em>learn more</em></p>
                            <p className="option-subtext">about our products</p>
                        </div>
                    </div>
                </div>  
            </div>  
        </SecationView>
    )
}

export default SelectOptionComponentAbout
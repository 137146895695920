import React, { useState } from 'react';

import './CircleNumberComponent.css';

import childOneImage from '../../assets/images/CircleCountImages/childCircle1.png';
import childTwoImage from '../../assets/images/CircleCountImages/childCircle2.png';
import childThreeImage from '../../assets/images/CircleCountImages/childCircle3.png';
import childFourImage from '../../assets/images/CircleCountImages/childCircle4.png';
import childFiveImage from '../../assets/images/CircleCountImages/childCircle4.png';
import circleHeaderIcon from '../../assets/images/CircleCountImages/circleHeaderIcon.png';

function CircleNumberComponent() {

    const [activeElement, setActiveElement] = useState();

    let counterData = [
        {
            title: "Filling Forms is an excruciatingly time consuming process.",
            head: "USER CENTRIC FORMS",
            content: "Digital Harbor lets users fill data in an instant and also autofills saved information."
        },
        {
            title: "Information can also get lost in a string of unrelated data.",
            head: "INSIGHTFUL DATA",
            content: "Digital Harbor empowers forms with the ability to narrate a story via data and visualise it insightfully."
        },
        {
            title: "Security of data is a huge concern in regular solutions.",
            head: "SAVE DATA FOR LATER",
            content: "Digital Harbor keeps user data safe and secure for future purposes so they don't have to fill the same information several times."
        },
        {
            title: "Forms look so dreary and tiresome.",
            head: "PERSONALIZED FORMS",
            content: "Personalize and customize forms according to your need and purpose."
        },
        {
            title: "Complex data fails to offer contextual information.",
            head: "GUIDED USER JOURNEY",
            content: "Digital Harbor lets you envision data seamlessly with a Data Card and Graphics."
        }
        ,
        {
            title: "Information can also get lost in a string od unrelated data.",
            head: "INSIGHTFUL DATA",
            content: "Digital Harbor empowers forms with the ability to narrate a story via data and visualise it insightfully."
        }
    ]

    const handleActiveState = (val) => {
        val != activeElement ? setActiveElement(val) : setActiveElement();
    }

    return (
        <div className='circle-number-component-contianer'>
  
            <div className='circle-number-contianer'>
                <div className='outer-circle-for-border'></div>
                <div className='parent-circle-contianer'>
                    <div className='parent-circle-text-contianer mv-sf-head'>
                        WHAT MAKES SOCIAL FORMS <br />
                        <span className='parent-wow-contianer'>WOW<span className='parent-ques-contianer'>?</span></span>
                    </div>  
                    <div className='parent-circle-text-contianer mv-sf'>
                        WHAT MAKES<br /> SOCIAL FORMS <br /> 
                        <span className='parent-wow-contianer'>WOW<span className='parent-ques-contianer'>?</span></span>
                    </div>                 
                </div>
                
                <div onClick={() => handleActiveState(6)} className='child-circle inner-circle-6'>
                    <div className={'child-circle-number-contianer ' + (activeElement == 6 ? 'animate-number' : '')}>6</div>
                    <span className={'clild-image-contianer ' + (activeElement == 6 ? 'animate-image' : '')}>
                        <img className='child-circle-image' src={childFiveImage} alt='child1' />
                    </span>
                </div>

                <div onClick={() => handleActiveState(5)} className='child-circle inner-circle-5'>
                    <div className={'child-circle-number-contianer ' + (activeElement == 5 ? 'animate-number' : '')}>5</div>
                    <span className={'clild-image-contianer ' + (activeElement == 5 ? 'animate-image' : '')}>
                        <img className='child-circle-image' src={childFiveImage} alt='child1' />
                    </span>
                </div>

                <div onClick={() => handleActiveState(1)} className='child-circle inner-circle-1'>
                    <div className={'child-circle-number-contianer ' + (activeElement == 1 ? 'animate-number' : '')}>1</div>
                    <span className={'clild-image-contianer ' + (activeElement == 1 ? 'animate-image' : '')}>
                        <img className='child-circle-image' src={childOneImage} alt='child1' />
                    </span>
                </div>

                <div onClick={() => handleActiveState(2)} className='child-circle inner-circle-2'>
                    <div className={'child-circle-number-contianer ' + (activeElement == 2 ? 'animate-number' : '')}>2</div>
                    <span className={'clild-image-contianer ' + (activeElement == 2 ? 'animate-image' : '')}>
                        <img className='child-circle-image' src={childTwoImage} alt='child1' />
                    </span>
                </div>

                <div onClick={() => handleActiveState(4)} className='child-circle inner-circle-4'>
                    <div className={'child-circle-number-contianer ' + (activeElement == 4 ? 'animate-number' : '')}>4</div>
                    <span className={'clild-image-contianer ' + (activeElement == 4 ? 'animate-image' : '')}>
                        <img className='child-circle-image' src={childFourImage} alt='child1' />
                    </span>
                </div>

                <div onClick={() => handleActiveState(3)} className='child-circle inner-circle-3'>
                    <div className={'child-circle-number-contianer ' + (activeElement == 3 ? 'animate-number' : '')}>3</div>
                    <span className={'clild-image-contianer ' + (activeElement == 3 ? 'animate-image' : '')}>
                        <img className='child-circle-image' src={childThreeImage} alt='child1' />
                    </span>
                </div>
            </div>

            <div className='circle-number-info-contianer'>
                {
                    activeElement ? <div className='circle-number-title-contianer'>
                        <img className='emoji-icon' alt='emoji-icon' src={circleHeaderIcon} />
                        <p className='circle-number-title'>{counterData[activeElement - 1].title}</p>
                    </div> : ''
                }

                <div className={'discription-contianer-number-counter ' + (activeElement ? 'animate-dis-container' : '')}>
                    <div className='circle-number-heading-contianer'>
                        {activeElement ? counterData[activeElement - 1].head : ''}
                    </div>
                    <div className='circle-number-discription-contianer'>
                        <p className='circle-number-discription'>
                            {activeElement ? counterData[activeElement - 1].content : ''}
                        </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default CircleNumberComponent
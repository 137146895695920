import React from 'react';

import './DotConnectionHowwe.css';
import DotConnect from '../../assets/images/image11.png';

const DotConnectionHowwe = (props) => {
    return (
        <div className='banner-dot-bottom-howwe'>
            <img style={props.style} className='banner-dot-bottom-howwe-image' alt='upper' src={DotConnect} />
        </div>
    )
}

export default DotConnectionHowwe
import React from 'react';

import './MailComponentNav.css';

export const MailComponentNav = (props) => {
    return (
        <div className='mail-shape-contianer'>
            <div style={props.padding} className='mail-rounded-squre-container chat-message-mail tab-nav-content'>
                {
                    props.children
                }
            </div>
        </div>
    )
}
import React, { useState } from "react";

import { Row, Col, Button } from "react-bootstrap";

import "./WhyWe.css";
import Arrow from "../../assets/images/DownArrow.png";
import CardDot from "../../assets/images/ScrollYearCircle.png";
import CotactbgIcon from "../../assets/images/Vector.png";
import CotactIcon from "../../assets/images/Vectorface.png";
import Downdot from "../../assets/images/downdot.png";
import Image from "../../assets/images/Group2693.png";
import Dot from "../../assets/images/dot.png";
import DottedImage from "../../assets/images/image13.png";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import SecationView from "../../components/SectionView/SecationView";
import ShadowCard from "../../components/ShadowCard/ShadowCard";
import SelectOptionComponent from "../../components/SelectOptionComponent/SelectOptionComponent";
import AnimatedPage from "../AnimatedPage";
import TopQuaterCircle from "../../components/TopQuaterCircle/TopQuaterCircle";
import BackIcon from "../../components/BackIcon/BackIcon";
import BottomQuaterCircle from "../../components/BottomQuaterCircle/BottomQuaterCircle";
import DotConnection from "../../components/DotConnection/DotConnection";
import DotConnectionWhywe from "../../components/DotConnectionWhywe/DotConnectionWhywe";
import ProductText from "../../components/ProductText/ProductText";
import DotImage from "../../assets/images/image12.png";
import HalfCircle from "../../assets/images/Blueelipseto2.png";
import NodeGraph from "../../components/NodeGraph/NodeGraph";

const WhyWe = (props) => {
  const options = [
    {
      heading: "What?",
      subtext: "What do you mean by ‘social’ ?",
      link: "/social",
    },
    {
      heading: "How?",
      subtext: "See how out apps work",
      link: "/enterprice",
    },
    {
      heading: "Learn More",
      subtext: "About digital harbor?",
      link: "/aboutus",
    },
  ];
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  const [inputText, setInputText] = useState();

  return (
    <AnimatedPage>
      <div className="why-we-page-container">
        <TopQuaterCircle style={{ maxWidth: "700px" }} />
        <SecationView>
          <a href="/">
            <BackIcon />
          </a>
          <div className="why-we-heading">
            <h1 className="pageHeading">WE KNOW WHAT</h1>
            <h1 className="pageHeading">YOU ARE MISSING!</h1>
          </div>
          <div className="why-we-heading-mbl">
            <h1 className="pageHeading">WE KNOW </h1>
            <h1 className="pageHeading">WHAT YOU </h1>
            <h1 className="pageHeading">ARE MISSING!</h1>
          </div>
          <div className="why-we-content-text">
            <p className="pageSmallText whywe-para">
              You are missing enterprise applications focused on you and your
              needs.
            </p>
            <p className="pageSmallText whywe-para">
              You are missing enterprise applications which have the ability to
              drive collaboration; deliver hyper- personalized and immersive
              experiences that are simple, meaningful, yet enjoyable like a
              story; and guide you at every step by ‘being there’.
            </p>
          </div>
          <ProductText />
          <img
            className="down-arrow-image local-down-arrow-image-class"
            src={Arrow}
          />
        </SecationView>
        <img className="dot-halfimage whywe-dot" src={DotImage} />
        <div className="sec2-container">
          <div className="sec2-heading">
            <h1 className="pageSubHeading">WE UNDERSTAND THE NEEDS OF</h1>
            <h1 className="pageSubHeading">KNOWLEDGE WORKERS</h1>
            <p className="sec2-heading-sub-text">CLICK ON A NODE TO EXPLORE</p>
          </div>
          <div className="nodegraph-whywe">
            <NodeGraph />
          </div>
          <div className="sec2-heading mbl-heading">
            <h1 className="pageSubHeading">WE UNDERSTAND </h1>
            <h1 className="pageSubHeading">THE NEEDS OF</h1>
            <h1 className="pageSubHeading">KNOWLEDGE </h1>
            <h1 className="pageSubHeading">WORKERS</h1>
          </div>
          {/* <img className="dummyimage" src={Image} /> */}
        </div>
        <img className="whywe-right-half" src={HalfCircle} />
        {/* <div className='how-we-banner-animated-image whywe-animated'></ div> */}
        <img className="right-halfimage whywe-right" src={HalfCircle} />
        <img className="dotted-image" src={DottedImage} />
        <SecationView className="blueSecBG bg-dotted">
          <Row>
            <Col md={6} className="margin-vertical-center">
              <div className="sec3-right-container">
                <h3 className="pageSubHeading">DIGITAL HARBOR IS</h3>
                <h3 className="pageSubHeading">AN INNOVATION-</h3>
                <h3 className="pageSubHeading">FIRST COMPANY</h3>
                <p className="pageSmallText col-blue-subhead">
                  How can we claim this? History is proof.
                  <br /> Scroll through our history of innovation
                </p>
                <p className="pageSmallText">
                  Learn more about Digital Harbor {">"}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="sec3-left-contianer" id="scrollbar">
                <div className="scroll-view-card-container">
                  <div className="white-line-container top-borders-round"></div>
                  <div className="scroll-view-content-container">
                    <img src={CardDot} className="card-dot-image" />
                    <div className="scroll-view-text-xontent-container">
                      <span className="scroll-view-heading">1997-2003</span>
                      <div className="scroll-card-shadow-card-container">
                        <ShadowCard style={{ maxWidth: "350px" }}>
                          <p className="pageSmallText bullet-para">
                            <ul>
                              <li className="bullet-desc">
                                Launched first commercial Data Fusion platform
                                for Intelligence Sector
                              </li>
                              <li>
                                Created industry’s first Model-driven SOA
                                technology
                              </li>
                            </ul>
                          </p>
                        </ShadowCard>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="scroll-view-card-container">
                  <div className="white-line-container "></div>
                  <div className="scroll-view-content-container">
                    <img src={CardDot} className="card-dot-image" />
                    <div className="scroll-view-text-xontent-container">
                      <span className="scroll-view-heading">1997-2003</span>
                      <div className="scroll-card-shadow-card-container">
                        <ShadowCard style={{ maxWidth: "350px" }}>
                          <p className="pageSmallText">
                            <ul>
                              <li className="bullet-desc">
                                Launched first commercial Data Fusion platform
                                for Intelligence Sector
                              </li>
                              <li>
                                Created industry’s first Model-driven SOA
                                technology
                              </li>
                            </ul>
                          </p>
                        </ShadowCard>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="scroll-view-card-container">
                  <div className="white-line-container bottom-borders-round"></div>
                  <div className="scroll-view-content-container">
                    <img src={CardDot} className="card-dot-image" />
                    <div className="scroll-view-text-xontent-container">
                      <span className="scroll-view-heading">1997-2003</span>
                      <div className="scroll-card-shadow-card-container">
                        <ShadowCard style={{ maxWidth: "350px" }}>
                          <p className="pageSmallText">
                            <ul>
                              <li className="bullet-desc">
                                Launched first commercial Data Fusion platform
                                for Intelligence Sector
                              </li>
                              <li>
                                Created industry’s first Model-driven SOA
                                technology
                              </li>
                            </ul>
                          </p>
                        </ShadowCard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </SecationView>

        <SelectOptionComponent
          heading={
            "Our history of innovation has led us to <br/> reimagine business apps and create </br> aPPS that are truly ‘Social’"
          }
          middleHeading={"WHAT IS SOCIAL?"}
          options={options}
        />
        <DotConnectionWhywe />
        <BottomQuaterCircle />
      </div>
    </AnimatedPage>
  );
};

export default WhyWe;

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import GetInTouchSectionPopup from '../GetInTouchSectionPopup/GetInTouchSectionPopup';
import './CareersCardComponent.css';
import experience from '../../assets/images/experience.png';
import designation from '../../assets/images/designation.png';
const { useState, useEffect } = React;


const CareersCardComponent = () => {
    function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className='modal-content'>
            <GetInTouchSectionPopup />
            <Button onClick={props.onHide}>Close</Button>
            </div>
          </Modal>
        );
      }
    
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <div className='carrer-card-component'>
            <div className='cc-card-left-container'>
                <h2 className='pageSubHeading career-card-head'>SENIOR UX DESIGNER</h2>
                <div className='cc-bullet-point-exp'>
                    <img src={experience} alt='expavatar' className='expavatar'/>
                    <div>
                        <h3 className='cc-overall-text firstheading'>EXPERIENCE:</h3>
                        <h3 className='cc-overall-text secondheading'>4 - 6 Years</h3>
                    </div>
                </div>
                <div className='cc-bullet-point-exp'>
                    <img src={designation} alt='expavatar' className='expavatar'/>
                    <div>
                        <h3 className='cc-overall-text firstheading'>DESIGNATION:</h3>
                        <h3 className='cc-overall-text secondheading'>Sr UX Designer</h3>
                    </div>
                </div>
            </div>
            <div className='cc-card-button'>
                <div className='cc-card-right-container'>
                    <h3 className='cc-overall-text firstheading'>ROLES & RESPONSIBILITIES:</h3>
                    <p className='cc-overall-text cc-overall-text-para'>
                        Work closely with the Product Management Group<br /> to understand, review and contribute to Product<br />
                        requirement definitions Works closely and<br /> iteratively with Scrum team members to clarify functional...
                    </p>                             
                </div>
                <div className='applynow-button'>
                <button className='cc-apply-button' onClick={() => setModalShow(true)}>APPLY NOW</button>
                    <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)}/>
                </div> 
            </div>
        </div>
    )
}

export default CareersCardComponent


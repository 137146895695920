import React, { useState } from 'react';

import './CustomPieChartComponent.css'
import circleHeaderIcon from '../../assets/images/CircleCountImages/circleHeaderIcon.png';

const CustomPieChartComponent = () => {

    const [activeElement, setActiveElement] = useState(1);

    let counterData = [
        {
            head: "BUILD AROUND THE USER",
            content: "Understands the intent of the user and acts like a personal guide."
        },
        {
            head: "SEARCH BEYOND DATA",
            content: "Search any data as well as transaction in natural language."
        },
        {
            head: "POLYMORPHIC DISPLAY",
            content: "Displays serach results based on user behavior & device."
        },
        {
            head: "DYNAMIC SEARCH RESULT",
            content: "Search result are no longer tabular, it is rich in information & are actionable."
        }
    ]

    const changeActiveElement = (val) => {
        console.log("change element", val)
        setActiveElement(val)
    }

    return (
        <div className='pie-chart-component-container'>
            <div className='pie-contianer'>
                <div className='pie-inner-circle'>
                    <div className='pie-text-contianer-circle'>
                        <div className='pie-text-contianer'>
                            <h3 className='pie-heading'>{activeElement}</h3>
                            <p className='pie-discription'>
                                THINGS THAT MAKE US DIFFERNT
                            </p>
                        </div>
                    </div>
                </div>
                <div className='top-hemispare-contianer'>
                    <div onClick={() => changeActiveElement(1)} className={"circle-part-contianer quarter-circle-bottom-right" + (activeElement == 1 ? ' active' : '')}></div>
                    <div onClick={() => changeActiveElement(2)} className={"circle-part-contianer quarter-circle-bottom-left" + (activeElement == 2 ? ' active' : '')}></div>
                </div>
                <div className='bottom-hemispare-contianer'>
                    {/* <div onClick={() => changeActiveElement(5)} className={"circle-part-contianer quarter-circle-bottom-left" + (activeElement == 5 ? ' active' : '')}></div> */}
                    <div onClick={() => changeActiveElement(4)} className={"circle-part-contianer quarter-circle-top-right" + (activeElement == 4 ? ' active' : '')}></div>
                    <div onClick={() => changeActiveElement(3)} className={"circle-part-contianer quarter-circle-top-left" + (activeElement == 3 ? ' active' : '')}></div>
                </div>
            </div>
            <div className='pie-detials-container'>
                <div className={'pie-detials-shape-contianer ' + (activeElement ? 'animate-dis-container' : '')}>
                    <div className='pie-detials-heading'>
                    <img className='emoji-icon cp-emoji' alt='emoji-icon' src={circleHeaderIcon} />
                    <p className='pie-detials-heading cp-title'>
                        {activeElement ? counterData[activeElement - 1].head : ''}
                    </p>   
                    </div>
                    <div className='pie-detials-discription'>
                        <p className='pie-detials-discription-content'>
                            {activeElement ? counterData[activeElement - 1].content : ''}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomPieChartComponent
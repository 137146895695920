import React from 'react';
import './ContactUs.css';

import MapImage from '../../assets/images/map.png';
import Arrow from '../../assets/images/DownArrow.png';

import AnimatedPage from '../AnimatedPage';
import SecationView from '../../components/SectionView/SecationView';
import ShadowCard from '../../components/ShadowCard/ShadowCard';
import GetInTouchSection from '../../components/GetInTouchSection/GetInTouchSection';
import FooterComponent from '../../components/FooterComponent/FooterComponent';

import { BiBuildings, BiPhone } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import TopLeftQuaterCircle from "../../components/TopLeftQuaterCircle/TopLeftQuaterCircle";
import DotConnectionLeft from '../../components/DotConnectionLeft/DotConnectionLeft';
import BackIcon from "../../components/BackIcon/BackIcon";
import ProductText from '../../components/ProductText/ProductText';
import TopQuaterCircleSACC from '../../components/TopQuaterCircleSACC/TopQuaterCircleSACC';

const ContactUs = () => {

    return (
        <AnimatedPage>
            <div className='contact-us-page-contianer'>
            <TopLeftQuaterCircle />
            <TopQuaterCircleSACC />
            <DotConnectionLeft />
                <SecationView className='section-bg-margin-left section-bg-margin-left'>
                <BackIcon />
                    <div className="how-we-heading">
                        <h1 className="pageHeading">
                            WE BELIEVE IN <br />
                            INNOVATION FOR <br />
                            EVERYONE. <br />
                            GET IN TOUCH <br />
                            WITH US
                        </h1>
                    </div>
                    <div className="how-we-content-text">
                        <div className='how-we-hanglecontext-left'>
                            <img className="down-arrow-image how-local-down-arrow-image-class contct-arrow" src={Arrow} />
                        </div>
                    </div>
                    <ProductText />
                </SecationView>

                <div className='contact-data-container'>
                    <div className='contact-map-address-details-contianer'>
                        <img className='contact-map-image' alt='map-image' src={MapImage} />
                        <div className='address-details-container'>
                            <ShadowCard className={"blue-lin-grad-BG address-detials-container-card mv-contactcard"}>
                                <div className='constact-card-address-container'>
                                    <div className='constact-card-address-container-left'>
                                        <div className='const-card-left-heading-contianer'>
                                            <h6 className='const-card-left-heading'>HEADQUARTERS</h6>
                                            <span className='const-card-left-heading-bottom-border'></span>
                                        </div>
                                        <h6 className='const-card-left-subheading'>R&D CENTER</h6>
                                        <h6 className='const-card-left-subheading'>KNOWLEDGE CENTER</h6>
                                    </div>
                                    <div className='constact-card-address-container-right'>

                                        <div className='address-element-container'>
                                            <BiBuildings className='address-element-icon-building' />
                                            <p className='address-element-content'>
                                                Digital Harbor, Inc.<br />
                                                1775 Greensboro Station <br />
                                                Place, Suite 105 <br />
                                                www.d McLean, VA 22102 igitalharbor.com
                                            </p>
                                        </div>

                                        <div className='address-element-container address-element-container-margin-top'>
                                            <BiPhone className='address-element-icon' />
                                            <p className='address-element-content'>
                                                703 - 635 – 3477
                                            </p>
                                        </div>

                                        <div className='address-element-container address-element-container-margin-top'>
                                            <AiOutlineMail className='address-element-icon' />
                                            <p className='address-element-content'>
                                                innovations@dharbor.com
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </ShadowCard>
                        </div>
                    </div>
                </div>

                <GetInTouchSection heading={"MAKE YOUR BUSINESS SOCIAL! GET IN TOUCH TODAY."}/>

                <FooterComponent />

            </div>
        </AnimatedPage>
    )
}

export default ContactUs
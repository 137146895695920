import React from 'react';

import './BottomQuaterCircleSocial.css';
import bottomBannerQuaterCircle from '../../assets/images/bottomBannerQuaterCircle.png';

const BottomQuaterCircleSocial = (props) => {
    return (
        <div className='banner-bottom-half-cicle-imgae-contianer mv-social'>
            <img style={props.style} className='banner-bottom-half-cicle-imgae ' alt='bottom' src={bottomBannerQuaterCircle} />
        </div>
    )
}

export default BottomQuaterCircleSocial
import React from 'react';

import './DotConnectionWhywe.css';
import DotConnect from '../../assets/images/image17.png';

const DotConnectionWhywe = (props) => {
    return (
        <div className='banner-dot-bottom-whywe'>
            <img style={props.style} className='banner-dot-bottom-whywe-image' alt='upper' src={DotConnect} />
        </div>
    )
}

export default DotConnectionWhywe
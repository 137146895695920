import React, { useState, useRef, useEffect } from 'react';

import './GetInTouchSectionPopup.css';
import contactImage from '../../assets/images/contactImage.png';
import blackUserConCard from '../../assets/images/blackUserConCard.png';

import SecationView from '../SectionView/SecationView';
import ThemeButton from '../ThemeButton/ThemeButton';
import Button from 'react-bootstrap/Button';

import { BiPhone,BiHome, BiCloudUpload } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import GetInTouchSection from '../GetInTouchSection/GetInTouchSection';


const GetInTouchSectionPopup = () => {
    const steps = [
        {
            heading: 'NAME',
            key: 'FirstName',
            placeholder: 'First Name'
        },
        {
            heading: 'EMAIL',
            key: 'email',
            placeholder: 'Email'
        },
        {
            heading: 'HEADLINE',
            key: 'headline',
            placeholder: 'HEADLINE'
        },
        {
            heading: 'PH. NUMBER',
            key: 'phone',
            placeholder: 'Phone Number'
        }
        ,
        {
            heading: 'ADDRESS',
            key: 'address',
            placeholder: 'Address'
        }
    ]

    const [activeState, setActiveState] = useState(0);
    const [getTouchDone, setGetTouchDone] = useState(false);
    const [activeParent, setActiveParent] = useState(0);
    const [clickwebsite, setClickWebsite] = useState(false);

    const [formData, setFormData] = useState({
        FirstName: '',
        lastName: '',
        headline: '',
        email: '',
        phone: '',
        address: '',
    })

    const setValusToForm = (event, objKey) => {
        setFormData({ ...formData, [objKey]: event.target.value })
    }

    const handleEnterPress = (e, i) => {
        console.log("enter value is", e.key)
        if (e.key === 'Enter' || e.key === 'Tab') {
            if ((i + 1) < steps.length) {
                setActiveState(i + 1);
            } else {
                toggleGetInTouch();
                setActiveState();
            }
        }
    }

    const toggleGetInTouch = () => {
        setGetTouchDone(!getTouchDone)
    }

    const handleFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
    
        event.target.value = null;
      };
    const inputRef = useRef(null);
    const handleClick = () => {
        inputRef.current.click();
      };
      

 

      
    return (
        <div className='blueSecBG section-popup'>
            <SecationView className='get-in-touch-section-custom'>

                <div className='contact-get-touch-heading'>
                    <img alt='contact-iamge' src={contactImage} />
                    <h1 className='pageSubHeading contact-us-head-section-heading'>
                        {                          
                            !formData.FirstName ? 'YOU’RE APPLY FOR THE ROLE OF SENIOR UX DESIGNER.' :
                                <span>THANKS FOR GETTING IN TOUCH
                                    <span className='get-touch-name-container'>
                                        {" " + formData.FirstName}
                                    </span>!
                                </span>
                        }
                    </h1>
                </div>
                { !clickwebsite &&
                <>
                    <div className='apply-website'> 
                        <button className='apply-website-content' onClick={()=>setClickWebsite(true)}>Continue to apply on our website</button>
                    </div>
                    <div className='apply-linked'> 
                        <button className='apply-linked-content'>Apply with Linkedin</button>
                    </div>
                </>
                }
                
                    { clickwebsite &&
                    <div className='parent-selector-contianer'>

<div className='parent-options-contianer'>
    <div className='parent-option-contianer' onClick={() => setActiveParent(0)}>
        <div className='parent-outer-circle outer-circle'>
            <span className={'inner-circle ' + (activeParent == 0 ? 'inner-parent-circle-selected' : '')}></span>
        </div>
        <div className={'step-label-contianer ' + (activeParent == 0 ? 'step-label-contianer-bottom-border' : '')}>
            PERSONAL INFO
        </div>
    </div>
    <div className='parent-option-contianer' onClick={() => setActiveParent(1)}>
        <div className='parent-outer-circle outer-circle'>
            <span className={'inner-circle ' + (activeParent == 1 ? 'inner-parent-circle-selected' : '')}></span>
        </div>
        <div className={'step-label-contianer ' + (activeParent == 1 ? 'step-label-contianer-bottom-border' : '')}>
            YOUR PROFILE
        </div>
    </div>
    <div className='parent-option-contianer' onClick={() => setActiveParent(2)}>
        <div className='parent-outer-circle outer-circle'>
            <span className={'inner-circle ' + (activeParent == 2 ? 'inner-parent-circle-selected' : '')}></span>
        </div>
        <div className={'step-label-contianer ' + (activeParent == 2 ? 'step-label-contianer-bottom-border' : '')}>
            APPLICATION DETAILS
        </div>
    </div>
</div>

{
    activeParent == 0 ?
        <div className='contact-sections-container'>
            <div className='stepper-contianer'>
                {
                    steps.map((e, i) => {
                        return (
                            <div key={i}>
                                <div className='stepper-step-content-container'>
                                    <div className='step-label-contianer'>
                                        {e.heading}
                                    </div>
                                    <div className='radio-and-line-continaer'>
                                        <div className='step-icon-contianer'>
                                            <div className='outer-circle' onClick={() => setActiveState(i)}>
                                                <span className={
                                                    activeState == i ? 'inner-circle inner-circle-selected' :
                                                        formData[e.key] ? 'inner-circle inner-circle-has-data' : 'inner-circle '
                                                }></span>
                                            </div>
                                        </div>
                                        {
                                            i < steps.length - 1 ? <div className='between-stepper-steps'></div> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className='container-text-content-container'>
                {
                    !getTouchDone ?
                        <div className='constact-text-fields-container'>

                            <input
                                className='contact-info-contianer active-state-text-field'
                                placeholder={steps[activeState].placeholder}
                                onChange={e => setValusToForm(e, steps[activeState].key)}
                                value={formData[steps[activeState].key]}
                                onKeyDown={e => handleEnterPress(e, activeState)}
                                type={steps[activeState].key == 'phone' ? 'number' : 'text'}
                            />

                        </div> :
                        <div className='get-in-touch-card-view-container'>
                            <img className='contact-user-card-image' src={blackUserConCard} alt="user-image" />
                            <div className='user-prifile-details-cotnainer'>
                                <h3 className='contact-user-profile-card-username'>
                                    {formData.FirstName + " " + formData.lastName}
                                </h3>
                                <span className='contact-user-profile-card-headline'>{formData.headline}</span>
                                <div className='contact-user-profile-card-contact-container'>

                                    <div className='address-element-container'>
                                        <AiOutlineMail className='address-element-icon' />
                                        <p className='address-element-content'>
                                            {formData.email}
                                        </p>
                                    </div>

                                    <div className='address-element-container'>
                                        <BiPhone className='address-element-icon' />
                                        <p className='address-element-content'>
                                            {formData.phone}
                                        </p>
                                    </div>

                                    <div className='address-element-container'>
                                        <BiHome className='address-element-icon' />
                                        <p className='address-element-content'>
                                            {formData.address}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                }
            </div>
        </div> :
        <div className='message-screen-contianer'>
            <BiCloudUpload className='cloudupload-element-icon' />
            <input style={{display: 'none'}} ref={inputRef} type="file" onChange={handleFileChange} />
            <ThemeButton onClick={handleClick} style={{marginTop: '20px',marginLeft: '20px'}} heading={"Upload Resume"}/>
        </div>
}

                    </div>
                    }
                
            </SecationView>
        </div>
    );
}

export default GetInTouchSectionPopup
import React from 'react';

import './SocialChat.css';
import ScSection3Image from '../../assets/images/ScSection3Image.png';
import ScSec4Image from '../../assets/images/ScSec4Image.png';
import scChat2Icon from '../../assets/images/SocialChat/scChat2Icon.png';
import scChat4Icon from '../../assets/images/SocialChat/scChat4Icon.png';
import contactImage from '../../assets/images/contactImage.png';
import chatFunctionContianer from '../../assets/images/chatFunctionContianer3.png';
import chatIcon from '../../assets/images/Group2737.png';
import chatIcon2 from '../../assets/images/Group2696.png';
import groupIcon from '../../assets/images/Group.png';
import Arrow from '../../assets/images/Arrow1.png';
import Circle from '../../assets/images/Blueelipsetop4.png';
import DialogueMessage from '../../assets/images/Group2891.png';
import DialogueMessage2 from '../../assets/images/Group2898.png';
import DialogueMessage3 from '../../assets/images/Group2902.png';


import { BsArrowUp, BsArrowDown } from 'react-icons/bs'

import AnimatedPage from '../AnimatedPage';
import {
    ChatShapeChatComponent,
    ChatShapeReplyComponent,
    WhiteChatShapeChatComponent,
    ChatShapeChatComponentSocialChat,
    SmallChatShape
} from '../../components/chatShapeComponent/chatShapeComponent';
import ThemeButton from '../../components/ThemeButton/ThemeButton';
import VideoPlayerComponent from '../../components/VideoPlayerComponent/VideoPlayerComponent';
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import TopQuaterCircle from "../../components/TopQuaterCircle/TopQuaterCircle";
import BackIcon from "../../components/BackIcon/BackIcon";
import ProductText from '../../components/ProductText/ProductText';
import GetInTouchSection from '../../components/GetInTouchSection/GetInTouchSection';

const SocialChat = () => {
    return (
        <AnimatedPage>
            <div className='sc-page-contianer'>
            <TopQuaterCircle style={{maxWidth: '700px'}}/>
                <div className='sc-banner-contianer'>
                <a href='/enterprice'><BackIcon /></a>
                    <h3 className='product-page-name-heading chat'>YOODLE:</h3>
                    <h3 className='product-page-name-heading chat-sub'>COLLABORATION SUITE</h3>
                    <h3 className='product-page-name-heading chat-heading'>SOCIAL CHAT</h3>
                    <div className='head-banner-chat-records-container'>
                        <div>
                            <ChatShapeChatComponentSocialChat>
                                <h1 className='pageHeading'>Empowering you to</h1>
                            </ChatShapeChatComponentSocialChat>
                            <img className='ss-chaticon' src={chatIcon} alt='chat Icon' />
                        </div>
                        <div className='banner-chat-reply-container'>
                            <ChatShapeReplyComponent>
                                <h1 className='pageHeading'>Work together</h1>
                            </ChatShapeReplyComponent>
                        </div>
                        <ThemeButton style={{ marginTop: '4em' }} heading="EXPERIENCE" />
                        <ProductText />
                    </div>
                </div>

                <img src={Arrow} className='arrow' alt='arrow' />

                <div className='sc-sec-2-continaer'>
                    <h1 className='pageHeadingMed sc-subhead'>ARE YOU TIRED OF <br /> <span className='sc-blue-text'>CONVERSING</span> WITHOUT <br />
                        <span className='sc-blue-text'>CONTEXT</span>?
                    </h1>
                    <div className='sc-sec-2-para-div-contianer'>
                    <img src={DialogueMessage2} className='DialogueMessage2' alt='DialogueMessage2' />
                    <img src={DialogueMessage} className='DialogueMessage' alt='DialogueMessage' />
                    
                    <img src={DialogueMessage3} className='DialogueMessage3' alt='DialogueMessage3' />
                        {/* <p className='pageSmallText'>
                            Unclear communication, complexities to track tasks discussed during calls or texts,
                            or lack of mutual understanding due to various reasons lead to poor
                            efficiency and productivity.
                        </p>
                        <p className='pageSmallText'>
                            Some chat tools remain same for almost 20 years now offering no scope for collaboration
                            which makes it hard for the teams across geographies, functions and roles to come together
                            and work in a structured and strategic way.
                        </p> */}
                    </div>
                </div>

                <div className='sc-sec-3-contianer'>
                    <div className='sc-sec-3-image-contianer'>
                        <img src={ScSection3Image} className='sc-sec-3-image' alt='compouer image' />
                    </div>
                    <div className='sc-sec-3-content-contianer'>
                        <ChatShapeChatComponent>
                            <h3 className='pageSubHeading sc-head sc-chtmessage'>
                                Imagine a world of <br />Collaboration that<br /> <span className='sc-blue-text'><img className='ss-' src={chatIcon2} alt='chat Icon' /> goes beyond <br />‘communicating’</span> to virtually  
                                 <img className='ss-' src={groupIcon} alt='chat Icon' /><span className='sc-blue-text'> ‘working <br />together’.</span>
                            </h3>
                            <h3 className='pageSubHeading sc-head mv-chtmessage'>
                                Imagine a world  <br />of Collaboration that <span className='sc-blue-text'>goes beyond</span><br /> <span className='sc-blue-text'><img className='ss-' src={chatIcon2} alt='chat Icon' />  ‘communicating’</span><br /> to virtually 
                                <img className='ss-' src={groupIcon} alt='chat Icon' /><span className='sc-blue-text'> ‘working <br />together’.</span>
                            </h3>
                        </ChatShapeChatComponent>
                        <div className='sc-sec-3-paras-contianer'>
                            <p className='pageSmallText sc-paragraph'>
                                Yoodle's Social Chat is world’s first truly contextual and <br /> collaborative chat. With Social Chat,
                                you can focus on <br /> getting your task done while Chat helps you connect the <br /> dots for you and assistyou at the moment of need.
                            </p>
                            <p className='pageSmallText sc-paragraph'>
                                Social Chat builds a smooth road for easy collaboration with <br /> one or more people/team.
                                It also enables seamless sharing <br /> of documents, images, videos, files and more.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='sc-sec-4-contianer'>
                    <div className='sc-sec-4-continer-left'>
                        <div className='sc-sec-4-heading-container'>
                            <div className='sc-sec-4-heading-padding'>
                                <h2 className='pageHeadingMed'>WHAT MAKES <br /> SOCIAL CHAT </h2>
                                <h1 className='pageHeading'><span className='sc-yellow-text'>WOW</span>?</h1>
                            </div>
                        </div>
                    </div>
                    <div className='sc-sec-4-continer-right'>
                        <div className='sc-sec-4-chat-contianer'>
                            <div className='sc-sec-4-chat-arrow-contianer'>
                                <div className='sc-sec-4-up-down-arrow'>
                                    <BsArrowUp className='arrow-size-up-down-sc' />
                                    <BsArrowDown className='arrow-size-up-down-sc' />
                                </div>
                            </div>
                            <div className='sc-sec-4-chats-contianer' id="scrollbar">
                                <WhiteChatShapeChatComponent outerStyle={{ maxWidth: '500px', marginTop: '80px' }} padding={{ padding: '20px 50px' }}>
                                    <p className='sc-sec-4-chat-text'>
                                        Regular solutions support<br /> day-to-day conversation,<br /> but not collaboration.
                                    </p>
                                    <p className='chat-time-para'>11:00 pm</p>
                                </WhiteChatShapeChatComponent>

                                <ChatShapeReplyComponent outerStyle={{ maxWidth: '500px', float: 'right' }} padding={{ padding: '20px 50px' }}>
                                    <img src={scChat2Icon} className='sc-chat-icon' alt='computer icon' />
                                    <p className='sc-sec-4-chat-reply-text'>
                                        Social Chat allows user to collaborate, take actions and work together dynamically.
                                    </p>
                                    <p className='chat-reply-time-para'>11:00 pm</p>
                                </ChatShapeReplyComponent>

                                <WhiteChatShapeChatComponent outerStyle={{ maxWidth: '500px' }} padding={{ padding: '20px 50px' }}>
                                    <p className='sc-sec-4-chat-text'>
                                        I sometimes forget to follow up with tasks I assigned via texting.
                                    </p>
                                    <p className='chat-time-para'>11:02 pm</p>
                                </WhiteChatShapeChatComponent>

                                <ChatShapeReplyComponent outerStyle={{ maxWidth: '500px', float: 'right' }} padding={{ padding: '20px 50px' }}>
                                    <img src={scChat4Icon} className='sc-chat-icon' alt='computer icon' />
                                    <p className='sc-sec-4-chat-reply-text'>
                                        Social Chat will remind you to follow-up on tasks effortlessly.
                                    </p>
                                    <p className='chat-reply-time-para'>11:03 pm</p>
                                </ChatShapeReplyComponent>

                                <WhiteChatShapeChatComponent outerStyle={{ maxWidth: '500px' }} padding={{ padding: '20px 50px' }}>
                                    <p className='sc-sec-4-chat-text'>
                                        Regular solutions support day-today conversation, but not communication.
                                    </p>
                                    <p className='chat-time-para'>11:04 pm</p>
                                </WhiteChatShapeChatComponent>

                                <ChatShapeReplyComponent outerStyle={{ maxWidth: '500px', float: 'right' }} padding={{ padding: '20px 50px' }}>
                                    <img src={scChat2Icon} className='sc-chat-icon' alt='computer icon' />
                                    <p className='sc-sec-4-chat-reply-text'>
                                        Digital Harbor allows a user to communicate instantly with prompts and suggestive text.
                                    </p>
                                    <p className='chat-reply-time-para'>11:04 pm</p>
                                </ChatShapeReplyComponent>

                                <WhiteChatShapeChatComponent outerStyle={{ maxWidth: '500px' }} padding={{ padding: '20px 50px' }}>
                                    <p className='sc-sec-4-chat-text'>
                                        Certain information can get lost between several texts.
                                    </p>
                                    <p className='chat-time-para'>11:05 pm</p>
                                </WhiteChatShapeChatComponent>

                                <ChatShapeReplyComponent outerStyle={{ maxWidth: '500px', float: 'right' }} padding={{ padding: '20px 50px' }}>
                                    <img src={scChat2Icon} className='sc-chat-icon' alt='computer icon' />
                                    <p className='sc-sec-4-chat-reply-text'>
                                        Social Chat enables knowledge workers to access right information at the right time by leveraging Topics, Analytics and Tags.
                                    </p>
                                    <p className='chat-reply-time-para'>11:06 pm</p>
                                </ChatShapeReplyComponent>

                                <WhiteChatShapeChatComponent outerStyle={{ maxWidth: '500px' }} padding={{ padding: '20px 50px' }}>
                                    <p className='sc-sec-4-chat-text'>
                                        It is so difficult to track tasks and deadlines within a hundred other messages.
                                    </p>
                                    <p className='chat-time-para'>11:10 pm</p>
                                </WhiteChatShapeChatComponent>

                                <ChatShapeReplyComponent outerStyle={{ maxWidth: '500px', float: 'right' }} padding={{ padding: '20px 50px' }}>
                                    <img src={scChat2Icon} className='sc-chat-icon' alt='computer icon' />
                                    <p className='sc-sec-4-chat-reply-text'>
                                        Social Chat presents a repository of tasks and data in a comprehensive activity dashboard.
                                    </p>
                                    <p className='chat-reply-time-para'>11:11 pm</p>
                                </ChatShapeReplyComponent>

                            </div>
                        </div>
                        <img className='sc-sec-4-image' src={ScSec4Image} alt='computer image' />
                    </div>
                </div>

                <VideoPlayerComponent heading={"SOCIAL CHAT"}>
                    <div className='sc-s-chat-continaer'>
                        <div className='sc-s-chat-heading-container'>
                            <h1 className='pageHeading sc-cht-shape-head'>TRY IT FOR <br /> YOURSELF</h1>
                            <div className='sc-s-chat-para-chat-heading-shape-contianer'>
                                <p className='pageSmallText sc-s-chat-parap-contianer'>
                                    Our digital assistant Lucy is here to guide you through<br /> Social Chat.
                                    Type into the text field on the right to<br /> experience it for yourself!
                                </p>
                                <div className='chat-heading-shape-contianer'>
                                    <img src={contactImage} className='chat-shape-lady-image' alt='lady image' />
                                    <ChatShapeChatComponent padding={{ padding: '20px 50px' }}>
                                        <p className='chat-heading-shape-para'>
                                            Looks like you have a new message from<br /> Stacey.
                                            <span className='chat-heading-click-text'>Click on the chat to expand it</span>
                                        </p>
                                    </ChatShapeChatComponent>
                                </div>
                            </div>
                        </div>
                        <div className='chat-fun-contianer-div'>
                            <img alt='chat functionality' className='chat-fun-contianer' src={chatFunctionContianer} />
                        </div>
                    </div>
                    <img src={Circle} className='sc-cricle' alt='sc-circle' />
                </VideoPlayerComponent>
                

                <GetInTouchSection heading={"ADD THE WOW OF SOCIAL CHAT TO YOUR BUSINESS GET IN TOUCH TODAY."}/>
                <FooterComponent />
            </div>
        </AnimatedPage>
    )
}

export default SocialChat
import React, { useState } from "react";

import "./HowWe.css";
import Arrow from "../../assets/images/DownArrow.png";
import ComputerImage from "../../assets/images/ComputerWithCircle.png";
import TopQuaterCircle from "../../components/TopQuaterCircle/TopQuaterCircle";
import contactImage from "../../assets/images/contactImage.png";
import Searchi from "../../assets/images/searchi.png";
import Vector1 from "../../assets/images/Vector1.png";
import Vector2 from "../../assets/images/Vector2.png";
import Vector3 from "../../assets/images/Vector3.png";
import Vector5 from "../../assets/images/Vector5.png";
import video from "../../assets/images/video.png";
import vectorvideo from "../../assets/images/vectorvideo.png";

import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";

import SecationView from "../../components/SectionView/SecationView";
import SelectOptionComponentHowwe from "../../components/SelectOptionComponentHowwe/SelectOptionComponentHowwe";
import AnimatedPage from "../AnimatedPage";
import { ChatShapeChatComponentNav } from "../../components/chatShapeComponentNav/chatShapeComponentNav";
import { MailComponentNav } from "../../components/MailComponentNav/MailComponentNav";
import BackIcon from "../../components/BackIcon/BackIcon";
import DotConnectionHowwe from "../../components/DotConnectionHowwe/DotConnectionHowwe";
import BottomQuaterCircleHowwe from "../../components/BottomQuaterCircleHowwe/BottomQuaterCircleHowwe";
import ProductText from "../../components/ProductText/ProductText";
import DotImage from "../../assets/images/image12.png";
import NumberComponent from "../../components/NumberComponent/NumberComponent";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";

const HowWe = (props) => {
  const options = [
    {
      heading: "What?",
      subtext: "What do you mean by ‘social’ ?",
      link: "/social",
    },
    {
      heading: "How?",
      subtext: "See how out apps work",
      link: "/enterprice",
    },
  ];
  const label = { inputProps: { "aria-label": "Color white" } };
  const [inputText, setInputText] = useState("");
  const [details, setDetails] = useState({
    fname: "",
    lname: "",
    dob: "",
    jobtitle: "",
  });
  const [check, setCheck] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <AnimatedPage>
      <div className="how-we-page-container">
        <div className="how-we-animation-contianer">
          <TopQuaterCircle />
          <div className="how-we-banner-animated-image"></div>
          <SecationView style={{ zIndex: 1, position: "relative" }}>
            <div className="howwe-back">
              <a href="/">
                <BackIcon />
              </a>
            </div>
            <div className="how-we-heading ">
              <h1 className="pageHeading heading-title">
                IMAGINE A WORLD IN <br /> WHICH ENTERPRISE <br /> APPLICATIONS
                ARE <br /> DEFINED BY
              </h1>
            </div>
            <div className="how-we-content-text">
              <div className="how-we-hanglecontext-left">
                <p className="pageSmallText howwe-paragraph">
                  their ability to drive collaboration, deliver hyper-
                  <br /> personalized and immersive experiences for knowledge
                  <br /> workers and customers that are simple, meaningful, yet
                  <br /> enjoyable like a story.
                </p>
                <img
                  className="down-arrow-image how-local-down-arrow-image-class"
                  src={Arrow}
                />
              </div>
            </div>
            <ProductText />
          </SecationView>
        </div>

        <SecationView>
          <img className="dot-halfimage whywe-dot" src={DotImage} />
          <h1 className="pageSubHeading heading-2-sub-page-heading">
            WE’RE TRANSFORMING ENTERPRISE <br /> APPLICATIONS, NOT ONE
            APPLICATION AT A TIME <br /> BUT AN IMMERSIVE USER EXPERIENCE FROM{" "}
            <br /> BEGINNING TILL END!
          </h1>
        </SecationView>

        <SecationView className="blueSecBG">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" className="tab-view-buttons-container">
              <Nav.Item className="mv-nav-item">
                <Nav.Link
                  className="tab-view-nav-class mv-nav"
                  eventKey="first"
                  href="#"
                >
                  Reimagining <br />
                  Collaboration
                  <div className="tab-bottom-border" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="tab-view-nav-class mv-nav"
                  eventKey="second"
                  href="#"
                >
                  Reimagining <br />
                  user experience
                  <div className="tab-bottom-border" />
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="tab-view-nav-class mv-nav"
                  eventKey="third"
                  href="#"
                >
                  Reimagining business applications
                  <div className="tab-bottom-border" />
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="tab-view-nav-class mv-nav"
                  eventKey="four"
                  href="#"
                >
                  Reimagining
                  <br />
                  productivity
                  <div className="tab-bottom-border" />
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {/* <Sonnet /> */}
                <div className="heading-with-image-tab-container">
                  <div className="sec-heading-tab-container tab-first-heading">
                    <h3 className="pageSubHeading tab-content-heading">
                      Just <span class="tab-content-heading-nav">Yoodle</span>
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      to work{" "}
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      together{" "}
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      with data.
                    </h3>
                    <p className="pageSmallText sec4-subtext-container">
                      Collaboration is not just talking to each other
                      <br /> or stand-alone applications and scattered
                      <br /> information. Yoodle is true collaboration. How?
                      <br /> Yoodle is available on any piece of data within
                      <br /> any application.
                    </p>
                  </div>
                  <div className="sec-heading-tab-container mv-tab-content">
                    <h3 className="pageSubHeading tab-content-heading">
                      Just{" "}
                      <span className="tab-content-heading-nav">Yoodle</span>
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      to work together
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      with data.
                    </h3>
                  </div>
                  <div className="tab-nav-messaging">
                    <div className="chat-heading-shape-contianer">
                      <img
                        src={contactImage}
                        className="chat-shape-lady-image-tab"
                        alt="lady image"
                      />
                      <ChatShapeChatComponentNav className="tab-message">
                        <p className="chat-heading-click-head">Stacy</p>
                        <p className="chat-heading-click-desc">
                          Thanks for all the help last week{" "}
                          {check ? (
                            <span className="mail-heading-para-item1">
                              <img src={Vector1} /> <>@Aaron! </>
                            </span>
                          ) : (
                            "Aaron!"
                          )}{" "}
                          <br />
                          Anyone else is having the same issue he fixed
                          <br /> mine lickety-split.
                        </p>
                      </ChatShapeChatComponentNav>
                    </div>
                    <div className="chat-heading-shape-contianer">
                      <img
                        src={contactImage}
                        className="chat-shape-lady-image-tab"
                        alt="lady image"
                      />
                      <ChatShapeChatComponentNav className="tab-message">
                        <p className="chat-heading-click-head">Stacy</p>
                        <p className="chat-heading-click-desc">
                          Thanks for all the help last week{" "}
                          {check ? (
                            <span className="mail-heading-para-item1">
                              <img src={Vector1} /> <>@Aaron! </>
                            </span>
                          ) : (
                            "Aaron!"
                          )}{" "}
                          <br />
                          Anyone else is having the same issue he fixed
                          <br /> mine lickety-split.
                        </p>
                      </ChatShapeChatComponentNav>
                    </div>
                    <div className="chat-heading-shape-contianer tab-message-content mv-tab-content-switch">
                      <p>
                        {" "}
                        {check
                          ? "Digital Harbor Solution"
                          : "Toogle to make it social"}
                      </p>
                    </div>
                    <div className="chat-heading-shape-contianer tab-message-content mv-tab-content-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={check}
                        onChange={() => setCheck(!check)}
                      />
                    </div>
                    <div className="sec-heading-tab-container mv-tab-content-howwe">
                      <p className="pageSmallText sec4-subtext-container ">
                        Collaboration is not just talking to each other or
                        stand-alone applications and scattered information.
                        Yoodle is true collaboration. How? Yoodle is available
                        on any piece of data within any application.
                      </p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                {/* <Sonnet /> */}
                <div className="heading-with-image-tab-container">
                  <div className="sec-heading-tab-container">
                    <h3 className="pageSubHeading tab-content-heading">
                      For Internet{" "}
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      There is Google{" "}
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      For Everthing{" "}
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      Else Just <br />
                      <span class="tab-content-heading-nav">Noodle</span>
                    </h3>
                    <p className="pageSmallText sec4-subtext-container">
                      Noodle knows it all. Yet it is simple to use. It <br />
                      understand you, guides you and helps you to <br />
                      achieve your goals of everyday enterprise <br />
                      transactions - from start to finish.
                    </p>
                  </div>
                  <div className="tab-nav-messaging-sec">
                    <h3 className="pageSubHeading tab-content-heading">
                      Bug Tracking
                    </h3>
                    <p className="pageSmallText tab-sec">
                      Search Bugs On Projects Arc
                    </p>
                    <div className="cursor-tab">
                      <img
                        src={Searchi}
                        className="searchi"
                        alt="search image"
                      />
                      <input
                        value={inputText}
                        type="text"
                        onChange={(event) => setInputText(event.target.value)}
                        className="banner-input-whywe"
                      />
                      {!inputText ? <i></i> : ""}
                    </div>
                    <div className="tab-message-content tab-second">
                      <p>
                        {check
                          ? "Digital Harbor Solution"
                          : "Toogle to make it social"}
                      </p>
                    </div>
                    <div className="tab-switch-sec">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={check}
                        onChange={() => setCheck(!check)}
                      />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                {/* <Sonnet /> */}
                <div className="heading-with-image-tab-container">
                  <div className="sec-heading-tab-container">
                    <h3 className="pageSubHeading tab-content-heading">
                      <span className="tab-content-heading-nav">Toodle</span>{" "}
                      Your <br />{" "}
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      Way To{" "}
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      Immersive{" "}
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      Work <br />
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      Experiences.
                    </h3>
                    <p className="pageSmallText sec4-subtext-container">
                      Every thing is built around you. Experience a <br /> work
                      play without any siloes or anxiety of age- <br /> old
                      business processes and missing vital <br /> information.
                      Toodle weaves a memorable story <br />
                      for you bringing people, transactions and <br />{" "}
                      applications under one roof; seamlessly.
                    </p>
                  </div>
                  <div className="tab-nav-messaging-third">
                    {check ? (
                      <NumberComponent details={details} />
                    ) : (
                      <>
                        <h3 className="pageSubHeading tab-content-heading">
                          CREATE PROFILE
                        </h3>
                        <div className="tab-third cursor-tab-third">
                          <input
                            value={details.fname}
                            type="text"
                            onChange={(event) =>
                              setDetails({
                                ...details,
                                fname: event.target.value,
                              })
                            }
                            className="banner-input-whywe-third"
                            placeholder="First Name"
                          />
                          {!inputText ? <i></i> : ""}
                          <input
                            value={details.lname}
                            type="text"
                            onChange={(event) =>
                              setDetails({
                                ...details,
                                lname: event.target.value,
                              })
                            }
                            className="banner-input-whywe-third"
                            placeholder="Last Name"
                          />
                        </div>
                        <div className="tab-third-sec">
                          <input
                            value={details.dob}
                            type="date"
                            onChange={(event) =>
                              setDetails({
                                ...details,
                                dob: event.target.value,
                              })
                            }
                            className="banner-input-whywe-third dob"
                            placeholder="DOB"
                          />
                          <input
                            value={details.jobtitle}
                            type="text"
                            onChange={(event) =>
                              setDetails({
                                ...details,
                                jobtitle: event.target.value,
                              })
                            }
                            className="banner-input-whywe-third job"
                            placeholder="Job Title"
                          />
                        </div>
                        <div
                          className="tab-third-sec-button"
                          style={{ paddingTop: "20px", width: "85%" }}
                        >
                          <input
                            type="button"
                            className="banner-input-whywe-third third-button"
                            value="SEND"
                          />
                        </div>
                      </>
                    )}

                    <div className="tab-message-content tab-third-para">
                      <p>
                        {check
                          ? "Digital Harbor Solution"
                          : "Toogle to make it social"}
                      </p>
                    </div>
                    <div className="tab-switch-third">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={check}
                        onChange={() => setCheck(!check)}
                      />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="four">
                {/* <Sonnet /> */}
                <div className="heading-with-image-tab-container">
                  <div className="sec-heading-tab-container">
                    <h3 className="pageSubHeading tab-content-heading">
                      {" "}
                      With Digital <br />{" "}
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      Harbor Don't
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      Just Type,{" "}
                    </h3>
                    <h3 className="pageSubHeading tab-content-heading">
                      <span className="tab-content-heading-nav">Doodle</span>{" "}
                      It.
                    </h3>
                    <p className="pageSmallText sec4-subtext-container">
                      Doodle adds a bit of magic and fun to your <br /> daily
                      enterprice transactions. It makes text
                      <br /> actionable and communicative - enabling both
                      <br /> reader and writer in telling a complete story. It
                      <br /> can predict your next steps and allows you to{" "}
                      <br />
                      annotate anywhere and collaborate with others
                      <br /> within the document itself.
                    </p>
                  </div>
                  <div className="tab-nav-messaging">
                    <div className="chat-heading-shape-contianer">
                      <MailComponentNav className="tab-message">
                        <p>
                          <span className="mail-heading">from:</span>
                          <span className="mail-heading-para">
                            {check ? (
                              <span className="mail-heading-para-item1">
                                <img src={Vector1} /> <>@paul</>
                              </span>
                            ) : (
                              ""
                            )}{" "}
                            paul.b@finitesolutions.com
                          </span>
                        </p>
                        <p>
                          <span className="mail-heading">Subject:</span>
                          <span className="mail-heading-para2">
                            {check ? (
                              <span className="mail-heading-para-item1">
                                <img src={Vector2} /> <>CASE: 456</>
                              </span>
                            ) : (
                              ""
                            )}{" "}
                            CASE: 456
                          </span>
                        </p>
                        <p className="mail-heading-description">
                          Hey {check ? <img src={Vector3} /> : ""}Bryan,
                        </p>
                        <p className="mail-heading-description one-para">
                          The Problem we had with{" "}
                          {check ? (
                            <span className="mail-heading-para-item1">
                              <img src={Vector2} /> <>case 199</>
                            </span>
                          ) : (
                            "case 199"
                          )}{" "}
                          is <br /> happening with this one too. Could you look{" "}
                          <br /> into this?{" "}
                        </p>
                        <p className="mail-heading-description sec-para">
                          As you know discussed{" "}
                          {check ? (
                            <Button
                              className="mail-heading-para-item1"
                              aria-describedby={id}
                              onClick={handleClick}
                            >
                              <img src={Vector5} onClick={handleClick} />{" "}
                              <>presisting bugs</>
                            </Button>
                          ) : (
                            "presisting bugs"
                          )}
                          <br /> during last week's team video meeting, you
                          <br /> can refer to that for more information.{" "}
                        </p>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Typography sx={{ p: 2 }} className="mail-heading-video-model">
                            <img src={video} className="video-image"/>
                            <p className="video-para">Dev Team Meeting - July<br /> 20th 201</p>
                            <p className="video-desc"><img src={vectorvideo} className="vectorvideo"/>persisting bugs: 20:15 - 30:01 </p>
                            <p className="video-desc"><img src={vectorvideo} className="vectorvideo"/>persisting bugs: 20:15 - 30:01 </p>
                          </Typography>
                        </Popover>
                        <p className="mail-heading-description third-para">
                          {check ? (
                            <span className="mail-heading-para-item1">
                              <img src={Vector1} /> <>Ajith</>
                            </span>
                          ) : (
                            "Ajith"
                          )}{" "}
                          had a similar problem so maybe he
                          <br /> can help you out.{" "}
                        </p>
                        <p className="mail-heading-description four-para">
                          Cheers!{" "}
                        </p>
                        <p className="mail-heading-description five-para">
                          Paul Blart
                        </p>
                      </MailComponentNav>
                    </div>
                    <div className="tab-message-content tab-four-para">
                      <p>
                        {check ? "Digital Harbor Solution" : "Legacy solutions"}
                      </p>
                    </div>
                    <div className="tab-switch-four">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={check}
                        onChange={() => setCheck(!check)}
                      />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </SecationView>

        <div className="heading-with-image-container">
          <div className="sec-heading-container">
            <h3 className="pageSubHeading">
              BRIDGING THE GAP BETWEEN DATA, DOCUMENTS AND CONVERSATIONS.
            </h3>
            <p className="pageSmallText sec4-subtext-container howwe-tab">
              Digital Harbor products transform data-centric functionality into
              User- Centric Immersive Experiences. Data Records are transformed
              into Transaction Stories. Analytics is integrated in the form of
              suggestions and predictions directly into the apps. Users can
              search anything, anywhere within the App. They can share anything
              to anyone limited only by security <br />
              policies.
            </p>
            <p className="pageSmallText">
              Users can finally connect dots across transactions.
            </p>
          </div>

          <div className="sec-image-container">
            <img
              alt="ComputerImage"
              className="computer-image-container"
              src={ComputerImage}
            />
          </div>
        </div>

        <SelectOptionComponentHowwe
          heading={"ALL THIS IS POSSIBLE </br> WITH SOCIAL."}
          middleHeading={"WHAT IS SOCIAL?"}
          options={options}
        />
        <DotConnectionHowwe />
        <BottomQuaterCircleHowwe />
      </div>
    </AnimatedPage>
  );
};

export default HowWe;

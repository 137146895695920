import React from 'react';

import './BottomQuaterCircleHowwe.css';
import bottomBannerQuaterCircle from '../../assets/images/Blueelipsetop.png';

const BottomQuaterCircle = (props) => {
    return (
        <div className='banner-bottom-howwe'>
            <img style={props.style} className='banner-bottom-howwe-imgae' alt='bottom' src={bottomBannerQuaterCircle} />
        </div>
    )
}

export default BottomQuaterCircle
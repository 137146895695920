import React from 'react';

import { useTypewriter } from 'react-simple-typewriter';

import './SocialSearch.css';
import AnimatedPage from '../AnimatedPage';
import ShadowCard from '../../components/ShadowCard/ShadowCard';
import ThemeButton from '../../components/ThemeButton/ThemeButton';
import CustomPieChartComponent from '../../components/CustomPieChartComponent/CustomPieChartComponent';
import WhatMakesComponent from '../../components/WhatMakesComponent/WhatMakesComponent';
import VideoPlayerComponent from '../../components/VideoPlayerComponent/VideoPlayerComponent';
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import TopQuaterCircle from "../../components/TopQuaterCircle/TopQuaterCircle";
import BackIcon from "../../components/BackIcon/BackIcon";
import PieChartComponent from '../../components/PieChartComponent/PieChartComponent';

import Search from '../../assets/images/search.png';
import SearchImg from '../../assets/images/Group2937.png';
import SearchIcon from '../../assets/images/searchIcon.png';
import ClockIcon from '../../assets/images/clockIcon.png';
import ssSec4Image from '../../assets/images/ssSec4Image.png';
import item1 from '../../assets/images/ssDHProvidesIcons/item1.png';
import item2 from '../../assets/images/ssDHProvidesIcons/item2.png';
import item3 from '../../assets/images/ssDHProvidesIcons/item3.png';
import GetInTouchSection from '../../components/GetInTouchSection/GetInTouchSection';
import ProductText from '../../components/ProductText/ProductText';
import Arrow from '../../assets/images/Arrow1.png';
import Circle from '../../assets/images/howWeAnimatedCircle.png';
import Circle2 from '../../assets/images/howWeAnimatedCircle.png';

const SocialSearch = () => {

    const bannerCardStyle = {
        width: '700px',
        borderRadius: '40px',
        marginLeft:'80px'
    }

    const sec2SearchBarStyles = {
        borderRadius: '50px',
        width: '100%',
        backgroundImage: 'linear-gradient(319.24deg, rgba(255, 255, 255, 0.4) -4.1%, rgba(255, 255, 255, 0) 101.39%)',
        backdropFilter: 'blur(40px)',
        margin: '0px',
        zIndex: '11'
    }

    const { text } = useTypewriter({
        words: ["Discovery", "Insight"],
        loop: 0,
        typeSpeed: 200,
        deleteSpeed: 200,
        delaySpeed: 1000
    })

    const whatMakesData = {
        heading:'SOCIAL SEARCH',
        normal: [
            "Inconclusive search without the right keyword",
            "Several results which may not even be relevant",
            "No differentiation between file/link formats"
        ],
        digital: [
            {
                icon: item1,
                content: "System understands and adapts to the user to answer better"
            },
            {
                icon: item2,
                content: "Organizes and filters results to find the most accurate answer"
            },
            {
                icon: item3,
                content: "Meticulously filtered results for each search"
            }
        ]
    }

    return (
        <AnimatedPage>
            <div className='social-search-page-contianer'>
            <TopQuaterCircle style={{maxWidth: '700px'}}/>
                <div className='ss-banner-continaer'>
                    <a href='/enterprice'><BackIcon /></a>
                    <h3 className='product-page-name-heading chat'>NOODLE:</h3>
                    <h3 className='product-page-name-heading chat-sub'>BUSINESS APPLICATIONS SUITE</h3>
                    <h2 className='product-page-name-heading ssc-head'>SOCIAL SEARCH</h2>
                    <ShadowCard style={bannerCardStyle}>
                    <img className='ssc-chaticon' src={Search} alt='chat Icon' />
                        <div className='ss-banner-card-contianer'>                       
                            <img src={SearchIcon} className='ss-search-icon' alt='ss-search-icon' />
                            <h1 className='pageHeading'>
                                Transform search into {" "}
                                <span className='animated-text'>
                                    {text}
                                    <span className="ss-cursor">_</span>
                                </span>
                            </h1>
                        </div>
                    </ShadowCard>
                    <div className='sc-exp-button'>
                        <ThemeButton style={{ marginTop: '2em' }} heading="EXPERIENCE" />
                    </div>
                    <ProductText />
                </div>

                <img src={Arrow} className='arrow' alt='arrow' />

                <div className='sf-sec-2-heading-contianer'>
                    <h2 className='product-pages-heading ss-scc-2-heading'>
                        IS YOUR SEARCH <span className='blue-heading'>REALLY</span> <br />
                        GIVING YOU THE <span className='blue-heading'>RIGHT</span><br />
                        <span className='blue-heading'>INFORMATION ?</span>
                    </h2>
                    <h2 className='product-pages-heading mv-ss-scc-2-heading'>
                        IS YOUR SEARCH <br /><span className='blue-heading'>REALLY </span>  
                        GIVING YOU <br />THE <span className='blue-heading'>RIGHT</span><br />
                        <span className='blue-heading'>INFORMATION ?</span>
                    </h2>
                    <img className='ssc-form' src={SearchImg} alt='form' />
                </div>

                <div className='ss-sec-2-continaer'>
                    <div className='ss-sec-2-search-continaer'>
                        <ShadowCard style={sec2SearchBarStyles} className="mv-card">
                            <div className='ss-search-bar-contianer'>
                                <img src={SearchIcon} className='ss-search-icon-2' alt='ss-search-icon' />
                                <h2 className='pageSubHeading sc-pagehead'>WHAT IF YOUR SEARCH <span className="ss-cursor">|</span></h2>
                            </div>
                        </ShadowCard>
                        <div className='ss-sec-2-search-result-contianer'>
                            <div className='ss-sec-2-result-contianer'>
                                <div className='ss-sec-2-result-left-contianer'>

                                    <div className='ss-result-icon-with-heaing ss-result-icon-with-heaing-bottom'>
                                        <img src={ClockIcon} className='ss-search-icon-2' alt='ss-search-icon' />
                                        <h2 className='pageSubHeading sc-pagehead'>
                                            WHAT IF YOUR SEARCH <span className='animated-text'>IS<br /> NO LONGER A SEARCH,<br /> BUT IT'S AN ASK
                                            </span>
                                        </h2>
                                    </div>

                                    <div className='ss-result-icon-with-heaing'>
                                        <img src={SearchIcon} className='ss-search-icon-2 search-icon-result-1-icon' alt='ss-search-icon' />
                                        <div className='ss-search-2-result-heading-contianer'>
                                            <h2 className='pageSubHeading'>
                                                WHAT IF YOUR SEARCH <span className='animated-text'>CONNECT THE DOTS FOR<br /> YOU?</span>
                                            </h2>
                                        </div>
                                    </div>

                                </div>
                                <div className='ss-sec-2-result-right-contianer sc-para'>
                                    <p className='ss-sec-2-search-result-dis'>
                                        Noodle's Social Search enables<br /> a conversation by connecting<br /> your intent with data. It helps<br /> you work better by guiding you<br /> to ask the right questions and<br /> discover meaningful answers. 

                                    </p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                                <div className='ss-sec-2-result-right-contianer mv-para'>
                                    <p className='ss-sec-2-search-result-dis mv-para-desc'>
                                        Noodle's Social Search enables a conversation by connecting your intent with data. It helps you work better by guiding you to ask the right questions and discover meaningful answers. 

                                    </p>
                                </div>

                <CustomPieChartComponent />
                <PieChartComponent />
                {/* <img src={Circle2} className='ss-cricle-sec' alt='ss-circle' /> */}
                <div className='ss-sec-4-contianer'>
                    <div className='ss-sec-4-left-continaer'>
                        <h2 className='pageSubHeading'>SEARCH<br /> THE WAY<br /> YOU THINK</h2>
                        <p className='ss-sec-4-left-dis ss-sec-2-search-result-dis'>
                            If you have a question, Social<br /> Search has the answer.<br /> Searching for data will never be<br />
                            complicated and time-<br />consuming anymore.
                        </p>
                    </div>
                    <div className='ss-sec-5-right-continaer'>
                        <img src={ssSec4Image} className="crome-image-ss-sec-4" alt='crome-image-ss-sec-4' />
                    </div>
                </div>
                {/* <WhatMakesComponent data={whatMakesData} /> */}
                <img src={Circle} className='ss-cricle' alt='ss-circle' />
                <VideoPlayerComponent heading={"SOCIAL SEARCH"}/>
                <GetInTouchSection heading={"ADD THE WOW TO YOUR BUSINESS GET IN TOUCH TODAY."} />
                <FooterComponent/>
            </div>
        </AnimatedPage>
    )
}

export default SocialSearch
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CotactbgIcon from "../../assets/images/Vector.png";
import CotactIcon from "../../assets/images/Vectorface.png";
import NodeGraphCard from "../NodeGraphCard/NodeGraphCard";
import ShadowCard from "../ShadowCard/ShadowCard";
import "./NodeGraph.css";

const NodeGraph = () => {
  const [toggle, setToggle] = useState(true);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);
  const [toggle5, setToggle5] = useState(false);
  const [toggle6, setToggle6] = useState(false);

  const handleClick = () => {
    setToggle(true);
    setToggle2(false);
    setToggle3(false);
    setToggle4(false);
    setToggle5(false);
    setToggle6(false);
  };

  const handleClick2 = () => {
    setToggle(false);
    setToggle3(false);
    setToggle4(false);
    setToggle5(false);
    setToggle6(false);
    setToggle2(true);
  };
  const handleClick3 = () => {
    setToggle(false);
    setToggle2(false);
    setToggle4(false);
    setToggle5(false);
    setToggle6(false);
    setToggle3(true);
  };
  const handleClick4 = () => {
    setToggle(false);
    setToggle2(false);
    setToggle3(false);
    setToggle4(true);
    setToggle5(false);
    setToggle6(false);
  };
  const handleClick5 = () => {
    setToggle(false);
    setToggle2(false);
    setToggle3(false);
    setToggle4(false);
    setToggle5(true);
    setToggle6(false);
  };
  const handleClick6 = () => {
    setToggle(false);
    setToggle2(false);
    setToggle3(false);
    setToggle4(false);
    setToggle5(false);
    setToggle6(true);
  };

  return (
    <>
      <div className="graph-container">
        <div className="graph-content">
          <div className="graph-left">
            <div className="graph-centre">
              <img src={CotactbgIcon} className="bg__vector" />
              <img src={CotactIcon} className="face__vector" />
            </div>
            <div className="names">
              <div className="name-1" onClick={handleClick}>
                <div className="name-1-inner">
                  <div className={toggle ? "dot1 dot1blue" : "dot dot1 "}></div>
                  <div
                    className={toggle ? "line1 lineblue " : "line line1"}
                  ></div>
                </div>
                <p className={toggle ? " line1textblue " : "line1text"}>
                  Action within the conversation{" "}
                </p>
              </div>
              <div className="name-2" onClick={handleClick2}>
                <div className="name-2-inner">
                  <div
                    className={toggle2 ? "dot2 dot2blue" : "dot dot2 "}
                  ></div>
                  <div
                    className={toggle2 ? "line2 lineblue" : "line line2 "}
                  ></div>
                </div>
                <p className={toggle2 ? "line2textblue " : "line2text"}>
                  {" "}
                  GPS-like assistance at every step{" "}
                </p>{" "}
              </div>
              <div className="name-3" onClick={handleClick3}>
                <div className="name-3-inner">
                  <div
                    className={toggle3 ? "dot3 dot3blue" : "dot dot3 "}
                  ></div>
                  <div
                    className={toggle3 ? "line3 lineblue" : "line line3 "}
                  ></div>
                </div>
                <p className={toggle3 ? "line3textblue " : "line3text"}>
                  Self-managed and engaging
                </p>{" "}
              </div>
              <div className="name-4" onClick={handleClick4}>
                <div className="name-4-inner">
                  <div
                    className={toggle4 ? "dot4 dot4blue" : "dot dot4 "}
                  ></div>
                  <div
                    className={toggle4 ? "line4 lineblue" : "line line4 "}
                  ></div>
                </div>
                <p className={toggle4 ? "line4textblue " : "line4text"}>
                  Memory and intelligence of its own
                </p>
              </div>
              <div className="name-5" onClick={handleClick5}>
                <div className="name-5-inner">
                  <div
                    className={toggle5 ? "dot5 dot5blue" : "dot dot5 "}
                  ></div>
                  <div
                    className={toggle5 ? "line5 lineblue" : "line line5 "}
                  ></div>
                </div>
                <p className={toggle5 ? "line5textblue " : "line5text"}>
                  Proactive contextual guidance
                </p>{" "}
              </div>
              <div className="name-6" onClick={handleClick6}>
                <div className="name-6-inner">
                  <div
                    className={toggle6 ? "dot6 dot6blue" : "dot dot6 "}
                  ></div>
                  <div
                    className={toggle6 ? "line6 lineblue" : "line line6 "}
                  ></div>
                </div>
                <p className={toggle6 ? "line6textblue " : "line6text"}>
                  Unstructured data is the future
                </p>{" "}
              </div>
            </div>
          </div>
          <div className="graph-right">
            <ShadowCard
              style={{ maxWidth: "380px" }}
              className="nodegraph-card"
            >
              {toggle && (
                <NodeGraphCard
                  title="Action within the conversation"
                  desc="The user wants the data to be mobile between software. Business
              applications should have the potential to transfer the data as and when
              needed. This data can be of any type (audio, video, images, files, and
              more.)"
                  link=" See how this works in Social Text"
                />
              )}
              {toggle2 && (
                <NodeGraphCard
                  title="GPS-like assistance at every step"
                  desc="The user wants the data to be mobile between software. Business
            applications should have the potential to transfer the data as and when
            needed. This data can be of any type (audio, video, images, files, and
            more.)"
                  link=" See how this works in Social Text"
                />
              )}
              {toggle3 && (
                <NodeGraphCard
                  title="Self-managed and engaging"
                  desc="The user wants the data to be mobile between software. Business
             applications should have the potential to transfer the data as and when
             needed. This data can be of any type (audio, video, images, files, and
             more.)"
                  link=" See how this works in Social Text"
                />
              )}
              {toggle4 && (
                <NodeGraphCard
                  title="Memory and intelligence of its own"
                  desc="The user wants the data to be mobile between software. Business
            applications should have the potential to transfer the data as and when
            needed. This data can be of any type (audio, video, images, files, and
            more.)"
                  link=" See how this works in Social Text"
                />
              )}
              {toggle5 && (
                <NodeGraphCard
                  title="Proactive contextual guidance"
                  desc="The user wants the data to be mobile between software. Business
            applications should have the potential to transfer the data as and when
            needed. This data can be of any type (audio, video, images, files, and
            more.)"
                  link=" See how this works in Social Text"
                />
              )}
              {toggle6 && (
                <NodeGraphCard
                  title="Unstructured data is the future"
                  desc="The user wants the data to be mobile between software. Business
             applications should have the potential to transfer the data as and when
             needed. This data can be of any type (audio, video, images, files, and
             more.)"
                  link=" See how this works in Social Text"
                />
              )}
            </ShadowCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default NodeGraph;

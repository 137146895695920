import React from 'react';

import './Tap.css';
import tapBannerIcon from '../../assets/images/tapBannerIcon.png';
import tapBannerImage from '../../assets/images/tapBannerImage.png';
import tapSec2Image from '../../assets/images/tapSec2Image.png'
import item1 from '../../assets/images/TapIcons/item1.png';
import item2 from '../../assets/images/TapIcons/item2.png';
import item3 from '../../assets/images/TapIcons/item3.png';

import AnimatedPage from '../AnimatedPage';
import ThemeButton from '../../components/ThemeButton/ThemeButton';
import CarouselSlidesTD from '../../components/CarouselSlidesTD/CarouselSlidesTD';
import WhatMakesComponent from '../../components/WhatMakesComponent/WhatMakesComponent';
import VideoPlayerComponent from '../../components/VideoPlayerComponent/VideoPlayerComponent';
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import BackIconTD from "../../components/BackIconTD/BackIconTD";
import ProductText from '../../components/ProductText/ProductText';
import TopQuaterCircle from "../../components/TopQuaterCircle/TopQuaterCircle";
import Arrow from '../../assets/images/Arrow1.png';
import tap1 from '../../assets/images/tap1.png';
import tap2 from '../../assets/images/tap2.png';
import halfcircle from '../../assets/images/topBannerQuaterCircle.png';
import Circle from '../../assets/images/Blueelipsetop4.png';



const Tap = () => {

    const carouselData = [
        {
            heading: 'CONNECT YOUR PC AND PHONE',
            content: 'Harmonise connection between phone and PC',
            image: tap1
        },
        {
            heading: 'SEAMLESS SHARING',
            content: 'Share data and attachments among phone and PC seamlessly',
            image: tap2
        },
        {
            heading: 'REALTIME DATA TRANSFER',
            content: 'Capture & Share Images, Videos and Documents in Real Time'
        }
    ]

    const whatMakesData = {
        heading: 'TAP',
        normal: [
            "Transfer files from phone to upload on laptop ",
            "Hassle of having different files on separate devices",
            "Wires used to connect different devices"
        ],
        digital: [
            {
                icon: item1,
                content: "Upload documents from phone to laptop in one click."
            },
            {
                icon: item2,
                content: "Click image from your phone and upload to a form on your laptop efficiently."
            },
            {
                icon: item3,
                content: "Connect your phone and laptop wirelessly and seamlessly."
            }
        ]
    }

    return (
        <AnimatedPage>
            <div className='tap-page-contianer'>
            <TopQuaterCircle style={{maxWidth: '800px'}}/>
                <div className='tap-banner-continaer'>
                <a href='/enterprice'><BackIconTD /></a>
                    <div className='tap-banner-center-align'>
                        <div className='tap-heading-continaer'>
                            <h3 className='product-page-name-heading tap-head'>TAP: TETHERED <br /> ACCESS FROM PHONE</h3>
                            <h1 className='pageHeading'>Sharing data<br /> and documents<br /> made easy</h1>
                            <div className='tap-explore-but-div-contianer'>
                                <ThemeButton style={{ height: '40px' }} heading="EXPERIENCE" />
                                <img className='tap-banner-icon' src={tapBannerIcon} alt='banner icon' />
                            </div>
                            <ProductText />
                        </div>
                        <div className='tap-banner-image-contianer'>
                            <img src={tapBannerImage} className='tap-banner-image' alt='tap banner contiaer' />
                        </div>
                    </div>
                </div>

                <img src={Arrow} className='tap-arrow' alt='arrow' />
                
                <div className='tap-sec-2-contianer'>
                    <img className='sec-2-image' src={tapSec2Image} alt='sec 2 image' />
                    <div className='sec-2-content-contianer'>
                        <h1 className='pageSubHeading tap-content-head'>WHAT IF YOUR<br /> PHONE BECAME<br /> AN EXTENSION<br /> OF YOUR<br /> LAPTOP OR PC? </h1>
                        <p className='pageSmallText tap-sec-2-para tap-content-para'>
                            Combining the comfort and convenience of a mobile phone<br /> with the functionality of a
                            computer, Tethered Access from<br /> Phone or TAP is a new-age development that will
                            enable<br /> people to harmonise their devices seamlessly. Whether it's<br /> to fill forms
                            or send an email with the attachments in the<br /> other device, fret no more as TAP
                            will let you accomplish<br /> these tasks in seconds.
                        </p>
                    </div>
                </div>
                <CarouselSlidesTD data={carouselData} />
                <img src={halfcircle} className="halfcircle-td" />
                <WhatMakesComponent data={whatMakesData} />
                <img src={Circle} className='tap-cricle' alt='sc-circle' />
                <VideoPlayerComponent heading={"TAP"}/>
                <FooterComponent />
            </div>
        </AnimatedPage>
    )
}

export default Tap
import React from 'react';

import './DotConnection.css';
import DotConnect from '../../assets/images/image11.png';

const DotConnection = (props) => {
    return (
        <div className='banner-dot-half-cicle-imgae-contianer'>
            <img style={props.style} className='banner-dot-half-cicle-imgae' alt='upper' src={DotConnect} />
        </div>
    )
}

export default DotConnection
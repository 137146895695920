import React from 'react';

import './chatShapeComponentNav.css';

export const ChatShapeChatComponentNav = (props) => {
    return (
        <div className='chat-shape-contianer'>
            <div style={props.padding} className='rounded-squre-container chat-message tab-nav-content'>
                {
                    props.children
                }
            </div>
            <div className='mae-triangle'>
                <div className='parallelogram tab-nav-parall'></div>
            </div>
        </div>
    )
}

export const WhiteChatShapeChatComponent = (props) => {
    return (
        <div style={props.outerStyle} className='chat-shape-contianer'>
            <div style={props.padding} className='rounded-squre-container-white chat-message'>
                {
                    props.children
                }
            </div>
            <div className='mae-triangle'>
                <div className='parallelogram parallelogram-white'></div>
            </div>
        </div>
    )
}

export const ChatShapeReplyComponent = (props) => {
    return (
        <div style={props.outerStyle} className='chat-shape-contianer'>
            <div style={props.padding} className='rounded-squre-container chat-reply'>
                {
                    props.children
                }
            </div>
            <div className='reply-triangle'>
                <div className='parallelogram parallelogram-reply'></div>
            </div>
        </div>
    )
}
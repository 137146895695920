import React from 'react'

import { motion } from 'framer-motion'

function AnimatedPage({ children }) {

    const animations = {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -100 },
        transition:{ delay: 2 }
    }

    return (
        <motion.div
            variants={animations}
            initial={"initial"}
            animate={"animate"}
            exit={"exit"}
            transition={"transition"}
            
        >
            {
                children
            }
        </motion.div>
    )
}

export default AnimatedPage
import React, { useState } from 'react';

import './NumberComponent.css';

import childOneImage from '../../assets/images/CircleCountImages/childCircle1.png';
import childTwoImage from '../../assets/images/CircleCountImages/childCircle2.png';
import childThreeImage from '../../assets/images/CircleCountImages/childCircle3.png';
import childFourImage from '../../assets/images/CircleCountImages/childCircle4.png';
import childFiveImage from '../../assets/images/CircleCountImages/childCircle4.png';
import circleHeaderIcon from '../../assets/images/CircleCountImages/circleHeaderIcon.png';

function NumberComponent(props) {

    const [activeElement, setActiveElement] = useState(0);
    const [numberone, setNumberOne] = useState(0);

    let counterData = [
        {
            content: <> <input value={props.details.fname} type="text" className="banner-input-whywe-third name-input" placeholder='First Name'/> <input value={props.details.lname} type="text" className="banner-input-whywe-third name-input" placeholder='Last Name'/> </>
        },
        {
            content: <> <input value={new Date(props.details.dob).getDay()} type="text" className="banner-input-whywe-third" placeholder='Day'/> 
            <input value={new Date(props.details.dob).getMonth()} type="text" className="banner-input-whywe-third" placeholder='Month'/> 
            <input value={new Date(props.details.dob).getFullYear()} type="text" className="banner-input-whywe-third" placeholder='Year'/> </>
        },
        {
            content: <input value="" type="text" className="banner-input-whywe-third job" placeholder='Job title'/>
        }
    ]


    const handleActiveState = (val) => {
        if (val==1 && numberone<2) {
            setNumberOne(numberone+1)
        }
        val != activeElement ? setActiveElement(val) : setActiveElement();
    }

    return (
        <div className='number-component-contianer'>
  
            <div className='number-contianer'>
                <div className='parent-numbere-contianer'>
                    {/* <div className='parent-number-text-contianer mv-sf-head'>
                        WHAT MAKES SOCIAL FORMS <br />
                        <span className='parent-wow-contianer'>WOW<span className='parent-ques-contianer'>?</span></span>
                    </div>  
                    <div className='parent-circle-text-contianer mv-sf'>
                        WHAT MAKES<br /> SOCIAL FORMS <br /> 
                        <span className='parent-wow-contianer'>WOW<span className='parent-ques-contianer'>?</span></span>
                    </div>                  */}
                </div>
                
                <div onClick={() => handleActiveState(1)} className='child-number inner-number-1'>
                    <div className={'child-number-contianer ' + (activeElement == 1 ? 'animate-number' : '')}>1</div>
                    <span className={'clild-image-contianer ' + (activeElement == 1 ? 'animate-image' : '')}>
                        <img className='child-number-image' src={childOneImage} alt='child1' />
                    </span>
                </div>

                <div className='border-line'></div>

                <div onClick={() => handleActiveState(2)} className='child-number inner-number-2'>
                    <div className={'child-number-contianer ' + (activeElement == 2 ? 'animate-number' : '')}>2</div>
                    <span className={'clild-image-contianer ' + (activeElement == 2 ? 'animate-image' : '')}>
                        <img className='child-number-image' src={childTwoImage} alt='child1' />
                    </span>
                </div>

                <div onClick={() => handleActiveState(3)} className='child-number inner-number-3'>
                    <div className={'child-number-contianer ' + (activeElement == 3 ? 'animate-number' : '')}>3</div>
                    <span className={'clild-image-contianer ' + (activeElement == 3 ? 'animate-image' : '')}>
                        <img className='child-number-image' src={childThreeImage} alt='child1' />
                    </span>
                </div>
            </div>

            <div className='number-info-contianer'>

                <div className={'discription-contianer-number-counter ' + (activeElement ? 'animate-dis-container-number' : '')}>
                    <div className='number-heading-contianer'>
                        <p>WELCOME TO YOUR<br /> FIRST DAY AT WORK !</p>
                        
                        <p className='number-heading-contianer-title'>{numberone==0 ? 'LETS SET UP YOUR PROFILE' :"ENTER YOUR NAME BELOW" }<br /></p>
                    </div>
                    <div className='number-discription-contianer'>
                        <p className='circle-number-discription'>
                            {activeElement ? counterData[activeElement - 1].content : ''}
                        </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default NumberComponent
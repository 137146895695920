import React from 'react';

import './DotConnectionLeft.css';
import DotConnect from '../../assets/images/socialDot.png';

const DotConnection = (props) => {
    return (
        <div className='banner-dot-left-half-cicle-imgae-contianer mv-sw-dotimage'>
            <img style={props.style} className='banner-dot0left-half-cicle-imgae' alt='upper' src={DotConnect} />
        </div>
    )
}

export default DotConnection
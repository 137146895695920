import React from 'react';

import './chatShapeComponent.css';

export const ChatShapeChatComponent = (props) => {
    return (
        <div className='chat-shape-contianer'>
            <div style={props.padding} className='rounded-squre-container chat-message'>
                {
                    props.children
                }
            </div>
            <div className='mae-triangle'>
                <div className='parallelogram'></div>
            </div>
        </div>
    )
}

export const ChatShapeChatComponentSocialChat = (props) => {
    return (
        <div className='chat-shape-contianer'>
            <div style={props.padding} className='rounded-squre-container sc-chat-message'>
                {
                    props.children
                }
            </div>
            <div className='mae-triangle'>
                <div className='parallelogram'></div>
            </div>
        </div>
    )
}

export const WhiteChatShapeChatComponent = (props) => {
    return (
        <div style={props.outerStyle} className='chat-shape-contianer'>
            <div style={props.padding} className='rounded-squre-container-white sc-white-chat-message'>
                {
                    props.children
                }
            </div>
            <div className='mae-triangle'>
                <div className='parallelogram parallelogram-white-sc'></div>
            </div>
        </div>
    )
}

export const ChatShapeReplyComponent = (props) => {
    return (
        <div style={props.outerStyle} className='chat-shape-contianer'>
            <div style={props.padding} className='rounded-squre-container chat-reply'>
                {
                    props.children
                }
            </div>
            <div className='reply-triangle'>
                <div className='parallelogram parallelogram-reply'></div>
            </div>
        </div>
    )
}
import React from 'react'

import './ThemeButton.css'

function ThemeButton(props) {

    return (
        <button style={props.style} onClick={props.onClick} className='theme-button'>
            {props.heading}
        </button>
    )
}

export default ThemeButton
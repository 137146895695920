import React from 'react';

import Lottie from 'react-lottie-player'
import './Lottie.css';

const LottiePlayer = (props) => {
    return (
        <Lottie
            loop
            animationData={props.animation}
            play
            style={props.style}
            className="lottie"
        />
    )
}

export default LottiePlayer
import React, { useState } from 'react';

import './SocialForms.css';
import { BsChevronDown } from "react-icons/bs";

import AnimatedPage from '../AnimatedPage';
import ThemeButton from '../../components/ThemeButton/ThemeButton';
import SecTheeeImage from '../../assets/images/SocialFormScreen.png';
import SecTheeeImage2 from '../../assets/images/Group2539.png';
import CircleNumberComponent from '../../components/CircleNumberComponent/CircleNumberComponent';
import VideoPlayerComponent from '../../components/VideoPlayerComponent/VideoPlayerComponent';
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import TopQuaterCircle from "../../components/TopQuaterCircle/TopQuaterCircle";
import BackIcon from "../../components/BackIcon/BackIcon";
import Form from '../../assets/images/Group2934.png';
import Simile from '../../assets/images/Group2906.png';
import ProductText from '../../components/ProductText/ProductText';
import Circle from '../../assets/images/Blueelipsetop4.png';
import Arrow from '../../assets/images/Arrow1.png';
import GetInTouchSection from '../../components/GetInTouchSection/GetInTouchSection';

const SocialForms = () => {

    const [dropStatus, setDropStatus] = useState(false);
    const [inputText, setInputText] = useState();

    const handleDropStatus = () => {
        setDropStatus(!dropStatus)
    }

    const hangleTextFieldText = (val) => {
        setInputText(val);
        handleDropStatus();
    }

    return (
        <AnimatedPage>
            <div className='social-form-page-contianer'>
            <TopQuaterCircle style={{maxWidth: '700px'}}/>
                <div className='sf-banner-contianer'>
                <a href='/enterprice'><BackIcon /></a>
                    <h3 className='product-page-name-heading chat'>TOODLE:</h3>
                    <h3 className='product-page-name-heading chat-sub'>BUSINESS APPLICATIONS SUITE</h3>
                    <h3 className='product-page-name-heading sf-heading'>SOCIAL FORMS</h3>
                    <div className='banner-form-contianer'>
                        <div className='banner-top-2-fields-continaer'>
                            <input value={"Forms that"} disabled type={'text'} className="banner-input" />
                            <div className="cursor">
                                <input value={inputText} type="text" disabled className="banner-input" />
                                {
                                    !inputText ? <i></i> : ''
                                }
                            </div>
                        </div>
                        <div className='sf-large-droptdown-cotnainer'>
                            <div className='sf-custom-dropdown'>
                                {
                                    inputText ? <span>{inputText}</span> : <span>not only take inforamtion</span>
                                }
                                <span onClick={handleDropStatus}><BsChevronDown /></span>
                            </div>
                            <div onClick={() => hangleTextFieldText("but also give information")} className={'dropdown-slide-contianer ' + (dropStatus ? 'sf-dropdown-active' : '')}>
                                <span>but also give information</span>
                            </div>
                        </div>
                        <ThemeButton style={{ marginTop: '2em' }} heading="EXPERIENCE" />
                        <ProductText />
                    </div>
                    <img src={Arrow} className='arrow' alt='arrow' />
                </div>


                <div className='sf-sec-2-heading-contianer'>
                    <h2 className='product-pages-heading sf-scc-2-heading'>
                        DOES FORM-FILLING  <br />
                        <span className='blue-heading'>FILL YOU WITH FEAR?</span>
                    </h2>
                    <img className='sf-form' src={Form} alt='form' />
                </div>
                <div className='sf-sec-3-contianer'>
                    <div className='af-sec-3-left-contianer'>
                        <h2 className='product-pages-heading sf-sec-3-heading'>
                            IMAGINE A <br />WORLD WHERE <br />FORM-FILLING IS<br /> A <img className='sf' src={Simile} alt='form' /> <span className='blue-heading'>PLEASANT &<br /> STORYTELLING<br /></span> EXPERIENCE.
                        </h2>
                        <h2 className='product-pages-heading sf-sec-3-heading-mv'>
                            IMAGINE A WORLD<br /> WHERE FORM-<br />FILLING IS A<br />  <img className='sf' src={Simile} alt='form' /> <span className='blue-heading'>PLEASANT &<br /> STORYTELLING<br /></span> EXPERIENCE.
                        </h2>
                        <img src={SecTheeeImage2} className='sf-sec-3-screen-image2' alt='screen-image' />
                        <p className='pageSmallText sf-sec-3-content'>
                            Toodle's Social Forms not just offers a new social<br />  and collaborative experience of filling forms but it reimagines<br />  the business process itself. It is so visually enticing &<br />  interactive that users would feel that the form is guiding<br />  them like a story and immerse them in the form filling<br /> process.
                        </p>
                    </div>
                    <div className='af-sec-3-right-contianer'>
                        <img src={SecTheeeImage} className='sf-sec-3-screen-image' alt='screen-image' />                      
                    </div>
                    <img src={Circle} className='sf-cricle-sec' alt='sf-circle' />
                </div>
                <CircleNumberComponent />
                <img src={Circle} className='sf-cricle' alt='sf-circle' />
                <VideoPlayerComponent heading={"SOCIAL FORMS"}/>
                <GetInTouchSection heading={"ADD THE WOW TO YOUR BUSINESS GET IN TOUCH TODAY."} />
                <img src={Circle} className='sf-cricle-third' alt='sf-circle' />
                <FooterComponent />
            </div>
        </AnimatedPage>
    )
}

export default SocialForms
import React from "react";

import './Home.css'

import OptionComponent from '../../components/OptionComponent/OptionComponent'
import AnimatedPage from "../AnimatedPage";
import TopQuaterCircle from '../../components/TopQuaterCircle/TopQuaterCircle';
import DotConnection from "../../components/DotConnection/DotConnection";
import ProductText from '../../components/ProductText/ProductText';

const Home = (props) => {

    const options = [
        {
            heading: "Why",
            subtext: "are we reimagining business apps?",
            link: '/whywe'
        },
        {
            heading: "How",
            subtext: "are we reimagining business apps?",
            link: '/howwe'
        }
    ]
    return (
        <AnimatedPage>
            <div className="home-page-container" >
                <TopQuaterCircle style={{ maxWidth: '725px' }} />
                <div className="heading-container">
                    <h1 className="heading">WE’RE REIMAGINING</h1>
                    <h1 className="heading">BUSINESS APPS</h1>
                </div>
                <ProductText />
                <div className="option-component-validation">
                    <OptionComponent optionData={options} />
                    <DotConnection />
                </div>
                
            </div>
            
        </AnimatedPage>
    )

}

export default Home